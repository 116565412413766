import React from 'react'
import '../../Pages/MyProfile/orderPages/OrderLabTestPages/styles/labdetailscard.css'
import { NavLink, useNavigate } from 'react-router-dom';
import { lab_report_icon } from '../Medicine/Images';

const RadiologyOrderCard = ({ OrderData, Tabkey }) => {
    const navigate = useNavigate();
    const handleOrderDetails = (orderId) => {
        const data = {
            orderId: orderId,
            tabKey: Tabkey
        }
        navigate('/radio-order-details', { state: data })
    }

    return (
        <div className="order-cover-repo-wrap-box-con">
            {OrderData && OrderData?.data?.response?.length === 0 ? (
                <div class="empty-order-container">
                    <p class="empty-order-text">Your Radiology order list is empty. Explore and book now!</p>
                    <NavLink to={'/radio-home'}>
                        <button class="empty-order-button">
                            <img src={lab_report_icon} alt="" />
                            Explore Radiology</button>
                    </NavLink>
                </div>
            ) : (
                <>
                    {OrderData && OrderData?.data?.response?.map((item) => (
                        <div className="order-box-coverdetails" onClick={() => handleOrderDetails(item?.order_id)} key={item?.order_id}>
                            <div className="order-date-details">
                                <p className='lab-book-text'>Booked on {item?.order_details?.booking_date}</p>
                                <span
                                    style={{
                                        backgroundColor: item?.order_details?.order_status_color_code,
                                    }}
                                >
                                    {item?.order_details?.order_status}
                                </span>

                            </div>
                            <hr className='orderhr' />
                            <div className="orderDetails-product-box lab-order-card-lab">
                                <div className="left-box-cover">
                                    {item?.order_details?.patients?.map((item) => (
                                        <div className="details-box-repo-text lab-test-text-order">
                                            <p style={{ textTransform: 'capitalize' }}>Patient Name - {item?.Patient_name}</p>
                                            <p>Appoinment Date - {item?.appointment_date}</p>
                                        </div>
                                    ))}
                                </div>
                                {item?.order_details?.patients?.length > 1 && (
                                    <div className="more-appointment-box-repo">
                                        <span>+{item?.order_details?.patients?.length - 1} more appointments</span>
                                    </div>
                                )}

                                <div className="right-box-cover-details">
                                    <button>View Details</button>
                                </div>
                            </div>
                        </div>
                    ))}
                </>
            )}
        </div >
    )
};

export default RadiologyOrderCard