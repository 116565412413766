import React, { useEffect, useState } from "react";
import { useDispatch , useSelector } from "react-redux";
import {
  diagnosticCartAdd,
  diagnosticRemoveCart,
  radioCartAdd,
  radioRemoveCart,
} from "../../../Redux/actions/PostApiCall";
import {
  fetchRadioCartDetails,
  getRadioTestList , getRadioPackagesDescription , getTestDescription,
  fetchRadioCategoriesApi,
  fetchRadioPackagesApi,
  fetchRadioApi
} from "../../../Redux/actions/GetApiCall";


const useRadioHandleCart = (type) => {
  const dispatch = useDispatch();
  const [loadingItems, setLoadingItems] = useState(new Set());
  const testDataStatus = useSelector(state => state.radio_test_list_response.status);
  const cartResponseStatus = useSelector(state => state.radio_cart_add.status);
  const cartResponseSta = useSelector(state => state.radio_cart_add);
  const cartDetailsResponseStatus = useSelector(state =>state.radio_cart_details.status);
  const packageListResponseStatus = useSelector(state =>state.radiology_package_list_response.status);
  const radiologyCityData = JSON.parse(localStorage.getItem('selectedRadiologyData'));
  
  const [myApiLoader , setApiLoader] = useState(false)


  const handleCartAdd = async (test) => {
    setApiLoader(true);
    setLoadingItems(prev => new Set(prev).add(test.id));
    
    try {
      const CartData = {
        test_id: test?.type === "test" ? test.id : null,
        package_id: test?.type === "package" ? test.id : null,
      };
      
      await dispatch(radioCartAdd(CartData));
      await dispatch(fetchRadioCartDetails());
      
      if (test?.type === "package" && !test?.packdescriptionId) {
        if(test?.callCategories){
          await dispatch(fetchRadioCategoriesApi(radiologyCityData?.selectedCityId));
        }else{
          await dispatch(fetchRadioPackagesApi(radiologyCityData?.selectedCityId));
        }
      }
      else if( test?.type === "test" &&  (test?.descriptionId || test?.callTestdescriptionApi)) {
        await dispatch(fetchRadioApi(test?.descriptionId));
        if(test?.callCategories){
          await dispatch(fetchRadioCategoriesApi(radiologyCityData?.selectedCityId));
        }else{
          await dispatch(getRadioTestList(radiologyCityData?.selectedCityId));
        }
      }
      else if( test?.type === "package" && (test?.packdescriptionId || test?.callPackagedescriptionApi)) {
        await dispatch(getRadioPackagesDescription(test?.packdescriptionId));
        if(test?.callCategories){
          await dispatch(fetchRadioCategoriesApi(radiologyCityData?.selectedCityId));
        }else{
          await dispatch(fetchRadioPackagesApi(radiologyCityData?.selectedCityId));
        }
      }
      else if(test?.callCategories){
        await dispatch(fetchRadioCategoriesApi(radiologyCityData?.selectedCityId));
      }
      else {
        await dispatch(getRadioTestList(radiologyCityData?.selectedCityId));
        
      }
    } catch (error) {
      console.error("Error handling cart add:", error);
    } finally {
      setApiLoader(false); // Set loader to false after all API calls are complete
    }
  
    setLoadingItems(prev => {
      const newSet = new Set(prev);
      newSet.delete(test.id); // Remove the item from loading state once done
      return newSet;
    });
  };
  
  const handleRemoveCart = async (test) => {
    setApiLoader(true); // Set loader to true before starting API calls
    setLoadingItems(prev => new Set(prev).add(test.id));
    
    try {
      const CartData = {
        cartId: test.cart_id || null,
        clear_cart: null,
      };
      
      await dispatch(radioRemoveCart(CartData));
      await dispatch(fetchRadioCartDetails());
      
      if (test?.type === "package" && !test?.packdescriptionId) {
        if(test?.callCategories){
          await dispatch(fetchRadioCategoriesApi(radiologyCityData?.selectedCityId));
        }else{
          await dispatch(fetchRadioPackagesApi(radiologyCityData?.selectedCityId));
        }
      } 
      else if(test?.callCategories){
        await dispatch(fetchRadioCategoriesApi(radiologyCityData?.selectedCityId));
      }
      else if(test?.type === "package" && (test?.packdescriptionId || test?.callPackagedescriptionApi)){ 
        await dispatch(getRadioPackagesDescription(test?.packdescriptionId));
        if(test?.callCategories){
          await dispatch(fetchRadioCategoriesApi(radiologyCityData?.selectedCityId));
        }else{
          await dispatch(fetchRadioPackagesApi(radiologyCityData?.selectedCityId));
        }
      }
      else if(test?.type === "test" &&  (test?.descriptionId || test?.callTestdescriptionApi)){
        await dispatch(fetchRadioApi(test?.descriptionId));
        if(test?.callCategories){
          await dispatch(fetchRadioCategoriesApi(radiologyCityData?.selectedCityId));
        }else{
          await dispatch(getRadioTestList(radiologyCityData?.selectedCityId));
        }
      }
      else {
        await dispatch(getRadioTestList(radiologyCityData?.selectedCityId));
      }
    } catch (error) {
      console.error("Error handling cart remove:", error);
    } finally {
      setApiLoader(false); 
    }
  
    setLoadingItems(prev => {
      const newSet = new Set(prev);
      newSet.delete(test.id); 
      return newSet;
    });
  };

  return {
    handleCartAdd,
    handleRemoveCart,
    loadingItems , 
    testDataStatus ,
    cartResponseStatus , 
    cartDetailsResponseStatus ,
    packageListResponseStatus ,
    myApiLoader
  };
};

export default useRadioHandleCart;
