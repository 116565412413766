// SuccessFull.js

import React from 'react';
import Lottie from "lottie-react";
import SuccessFully from "../images/success.json";
import ErrorJson from "../AnimationJson/Error.json";

import Modal from 'react-bootstrap/Modal';

const SuccessRadiology = (props) => {
    const { show, successMessage, animationtype } = props;

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <Lottie animationData={SuccessFully} className='success-data-animation' />
                {<p className='success-msg'>Package Selected Successfully</p>}
            </Modal.Body>
        </Modal>
    )
}

export default SuccessRadiology;
