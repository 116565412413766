import React, { useEffect, useState } from 'react'
import { headerLogo } from '../../../images'
import useLocationState from '../hooks/useLocationState'
import '../Medicine_Style/eye.css'
import { EyeBanner } from '../Images'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router'
import { fetchPincode } from '../../../Redux/actions/GetApiCall'
import { dental_book, eye_book, FitnessPro } from '../../../Redux/actions/PostApiCall'
import { validateNumberInput } from '../../../utils/AllFunction'
import SuccessFull from '../../../Animation/SuccessFull'
import LoaderAnime from "../animation/prescription_loader";
import Medicine_Success from '../animation/Medicine_Success'

const EyeForm = () => {
    const { register, watch, handleSubmit, formState: { errors } } = useForm();
    const pincodeValue = watch("pincode");
    const [successmodal, setSuccessModal] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [city, setCity] = useState(""); // Local state for city
    const cityData = useSelector(state => state.medicine_pincode);
    const dispatch = useDispatch();
    const Data = useLocationState();
    console.log(Data)
    const navigate = useNavigate();
    const EyeData = useSelector(state => state?.eye_book_response)
    const DentalData = useSelector(state => state?.dental_book_response)
    // useEffect(() => {
    //     if (pincodeValue?.length === 6) {
    //         dispatch(fetchPincode(pincodeValue));
    //     } else if (pincodeValue === "") {
    //         setCity(""); // Clear the city state when pincode is empty
    //     }
    // }, [pincodeValue, dispatch]);

    // Update city state based on the Redux response
    // useEffect(() => {
    //     if (cityData?.data?.success) {
    //         setCity(cityData.data.city);
    //     }
    // }, [cityData]);

    const onSubmitHandler = async (data) => {
        setModalShow(true)
        const DataRepo = {
            partner: Data?.item?.name,
            pincode: data?.pincode,
            // city: city,
            city: data?.city,
            package_id: Data?.pack
        }
        if (Data?.type === "Eye") {
            await dispatch(eye_book(DataRepo))
        }
        else {
            await dispatch(dental_book(DataRepo))
        }
    };

    useEffect(() => {
        if (EyeData?.status === "success" || DentalData?.status === "success") {
            setSuccessModal(false)
            setSuccessModal(true)
            setTimeout(() => {
                navigate("/home")
            }, 2000);
        }
    }, [EyeData, DentalData])

    return (
        <div className='home-review-banner'>
            {modalShow && <LoaderAnime showModal={modalShow} />}
            <div className="home-app-header sticky-top">
                <div className="header-logo">
                    <img src={headerLogo} width="100%" alt="header-logo" />
                    <div className="user-name-text-repo">
                        <p style={{ fontSize: '16px', marginLeft: '10px' }}>{Data?.type}</p>
                    </div>
                </div>
            </div>

            <div class="banner form-banner-eye">
                <div className="form-eye-image-box">
                    <img src={Data?.item?.image} alt="" />
                </div>
                <p>{Data?.item?.name}</p>
            </div>

            <div className="repo-content-box">
                <form onSubmit={handleSubmit(onSubmitHandler)}>
                    <div className="repo-form-data-cover form-eye-repo mt-4">
                        <div className='mb-3'>
                            <label htmlFor="pincode">Enter your pincode</label>
                            <div className="inputcontainer">
                                <input
                                    placeholder="Enter Pincode"
                                    {...register("pincode", {
                                        required: "Pincode is required",
                                        pattern: {
                                            value: /^[0-9]{6}$/,
                                            message: "Invalid pincode"
                                        },
                                    })}
                                    // disabled={cityData?.loader === true}
                                    maxLength={6}
                                    onInput={validateNumberInput}
                                />
                                {/* {cityData?.loader === true && (
                                    <div className="icon-container">
                                        <i className="loader"></i>
                                    </div>
                                )} */}
                            </div>
                            {/* {cityData?.loader === false && pincodeValue && pincodeValue.length === 6 && (
                                <p className={cityData?.data?.success ? 'green' : 'red'}>
                                    {cityData?.data?.message}
                                </p>
                            )} */}

                            {errors.pincode && <p className="error-message">{errors.pincode.message}</p>}
                        </div>
                        <div>
                            <label htmlFor="landmark">Enter your city</label>
                            <input
                                type="text"
                                id="city"
                                // value={city}
                                placeholder='Enter City'
                                {...register("city", { required: "city is required" })}
                            />
                            {errors.city && <p className='error-msg' style={{ textAlign: "start", fontSize: "13px", marginTop: '5px' }}>{errors.city.message}</p>}
                        </div>
                    </div>
                    <button type="submit" className='repo-fit-btn' disabled={cityData?.data?.city === ""}>Submit</button>
                </form>
            </div>

            {(EyeData || DentalData) && <Medicine_Success showModal={successmodal} message={DentalData?.message || EyeData?.message} status={DentalData?.success || EyeData?.success} />}



        </div>
    );
};

export default EyeForm;
