import React, {useEffect , useState} from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { IoIosSearch } from "react-icons/io";
import { fetchDiagnosticCartDetails, getDiagnosticTestList  , getDiagnosticPackageList , fetchCategoriesTestList} from "../../Redux/actions/GetApiCall";
import { useDispatch } from "react-redux";
import { useFetchTestCategories } from "../../QueryClient/DiagnosticQuery";
import {
  handleNavigateSearch,
} from "../../utils/AllFunction";
import Loader from "../../Animation/Loader";
import AppFooter from "../Medicine/Components/Medicine_footer"
import NoDataFoundComp from "../../components/NoDataFound";
import { Toaster, toast } from "react-hot-toast";
import { useSelector } from "react-redux";

import {
  MY_ACCOUNT_API
} from "../../Constants";
// import TestbyCategories from "./components/TestbyCategories";
import TestbyCategorieSection from "./components/TestbyCategoriesSection";
import HealthPackages from "./components/HealthPackages";
import HowitWorks from "./components/HowitWorks";
import TestCard from "./components/TestCard";
import AppHeader from "../Medicine/Components/Medicine_Header"
import {
  appLabUploadImg,
  appMachineTest,
} from "../../images";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";

const Labtest = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const key = "diagnostics"
  const testData = useSelector(state => state.diagnostic_test_list_response);
  const cartDetailsResponse = useSelector(state =>state.diagnostic_cart_details);
  const diagnostic_package_list_response = useSelector(state =>state.diagnostic_package_list_response);
  const categories_test_list_response = useSelector(state =>state.categories_test_list_response);


  // const location = useLocation();
  // const queryParams = new URLSearchParams(location.search);
  // const V2token = queryParams.get("token");
  // const token = localStorage.getItem("ACCESS_TOKEN");

  useEffect(() =>{
    dispatch(fetchDiagnosticCartDetails())
    dispatch(getDiagnosticTestList())
    dispatch(getDiagnosticPackageList())
    dispatch(fetchCategoriesTestList())
  },[dispatch])


  return (
    <>
      <div className="home-review-banner">
        <AppHeader Title="Lab Tests" labCartCount={cartDetailsResponse?.data?.response?.cart_count} prescrip="diagnostics"/>
          <div className="app-searh-section lab-search-bar mt-0 mb-4">
            <div
              className="inputWithIcon"
              onClick={() => handleNavigateSearch(navigate)}
            >
              <input type="text" placeholder="Search" />
              <button>
                <IoIosSearch />
              </button>
            </div>
          </div>
          <div className="lab-upload">
            <div className="container upload-repo">
              <div className="lab-prescription-upload-box">
                <div className="row">
                  <div className="col-lg-7 col-md-7 col-7 lab-content-repo">
                    <div className="lab-prescription-content">
                      <h6>Upload Prescription</h6>
                      <p>Placed order with prescription</p>
                    </div>
                    <div className="lab-prescription-image">
                      <NavLink to={`/diagnostic-prescription`}>
                        <button>
                          <img
                            src={appLabUploadImg}
                            width="100%"
                            alt="lab-upload"
                          />
                          <p>Upload Prescription</p>
                        </button>
                      </NavLink>
                    </div>
                  </div>
                  <div className="col-lg-5 col-md-5 col-5 d-flex align-items-center justify-content-center">
                    <div className="test-image">
                      <img src={appMachineTest} width="100%" alt="test-machine" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="lab-book-categories mt-4">
            <div className="book-test">
              <h6>Book Test by categories</h6>
              <NavLink to="/categories-test">
                <p>View All</p>
              </NavLink>
            </div>
            {categories_test_list_response?.loader ? (
              <Loader />
            ) : (
              <TestbyCategorieSection categoryListData={categories_test_list_response?.data?.data} />
            )}
          </div>
          <div className="common-test-wrapper">
            <div className="book-test">
              <h6>Commonly Book Test</h6>
              <NavLink to="/common-book-test">
                <p>View All</p>
              </NavLink>
            </div>
              <div className="container">
                <Swiper
                  spaceBetween={10}
                  slidesPerView={1.7}
                  loop="true"
                  modules={[Autoplay]}
                  pagination={{ clickable: true }}
                >
                  <div className="swiper-wrapper">
                    {testData?.data?.data?.length > 0 ? (
                      testData?.data?.data?.map((test, index) => (
                        <SwiperSlide key={index}>
                          <div className="col-lg-12 common-repo mb-4">
                            <TestCard test={test}
                              loader={testData.loader}
                              callCategories = {false}
                            />
                          </div>
                        </SwiperSlide>
                      ))
                    ) : (
                      <NoDataFoundComp />
                    )}
                  </div>
                </Swiper>
              </div>
          </div>
          <HealthPackages packageData={diagnostic_package_list_response?.data}/>
          <HowitWorks/>
          <Toaster
            position="top-center"
            reverseOrder={false}
            gutter={8}
            containerClassName=""
            containerStyle={{}}
            toastOptions={{
              // Define default options
              className: "",
              duration: 2000,
              style: {
                background: "#fff",
                color: "#363636",
              },
            }}
          />
          {( cartDetailsResponse?.data?.response?.tests?.length > 0 || cartDetailsResponse?.data?.response?.packages?.length > 0 ) && (
            <AppFooter labCartData={cartDetailsResponse?.data?.response}  tabkey={key}/>
          )}
      </div>
    </>
  );
};

export default Labtest;
