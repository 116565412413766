import React from 'react'
import { headerLogo } from '../../images'
import { HospitalCategories } from '../../data'
import useLocationState from '../Medicine/hooks/useLocationState'
import { useNavigate } from 'react-router'

const Departments = () => {
    const AllDepartmentData = useLocationState();
    const navigate = useNavigate();
    const handleDoctorDetails = (id) => {
        navigate('/doctor', { state: id })
    }
    return (
        <div className='home-review-banner'>
            <div className="home-app-header sticky-top">
                <div className="header-logo">
                    <img src={headerLogo} width="100%" alt="header-logo" />
                    <div className="user-name-text-repo">
                        <p style={{ fontSize: '16px', marginLeft: '10px' }}>Departments</p>
                    </div>
                </div>
            </div>
            <div className="department-repo-clover-wrapper">
                {AllDepartmentData?.data?.data?.department?.map((item) => (
                    <div className='depart-clover-wrap repo-data-bima' key={item?.id} onClick={() => handleDoctorDetails(item?.id)}>
                        <div className="icon-path-rpeo">
                            <img src={item?.file_name} alt="" />
                        </div>
                        <p>
                            {item?.name}
                        </p>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Departments