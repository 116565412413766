import React, { useEffect, useState } from 'react';
import { Tab, Row, Col, Nav } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from 'swiper/modules';
import '../../style/orderDetails.css';
import Order_Card from './Order_Card';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDoctorOrderList, fetchLabOrderList, fetchorderhistory, fetchRadiologyOrderList, fetchTeleconsultationList } from '../../../../Redux/actions/GetApiCall';
import MediLoader from '../../../Medicine/animation/MediLoader';
import LabOrderCard from '../OrderLabTestPages/Components/LabOrderCard';
import TeleconsultationOrderCard from '../OrderTeleconsultation/components/TeleconsultationOrderCard';
import { NavLink, useLocation } from 'react-router-dom';
import { IoHome } from 'react-icons/io5';
import RadiologyOrderCard from '../../../Radio/RadiologyOrderCard';
import DoctorConsultationCard from '../OrderTeleconsultation/components/DoctorConsultationCard';
import useFetchData from '../../../../CustomHooks/useFetchData';

const OrderDetailsPage = () => {
    const dispatch = useDispatch();
    const orderHistoryData = useSelector(state => state.order_history);
    const orderLabHistoryData = useSelector(state => state.lab_order_details);
    const orderRadioHistoryData = useSelector(state => state.radiology_order_details);
    const orderTeleconsultationHistoryData = useSelector(state => state.teleconsultation_list);
    const orderDoctorHistoryData = useSelector(state => state.doctor_order_list);
    const location = useLocation();
    const [key, setKey] = useState(() => sessionStorage.getItem("selectedTab") || "first"); // Retrieve saved tab key or default to "first"
    const height = "75vh";

    useEffect(() => {
        dispatch(fetchorderhistory());
        dispatch(fetchLabOrderList());
        dispatch(fetchRadiologyOrderList());
        dispatch(fetchTeleconsultationList());

    }, [dispatch]);

    const fetchOPDData = async (page) => {
        try {
            const response = await dispatch(fetchDoctorOrderList(page)); // Replace with the correct API call
            return response || { data: [], total_pages: 0 }; // Ensure valid response structure
        } catch (error) {
            console.error('Error fetching OPD data:', error);
            return { data: [], total_pages: 0 };
        }
    };

    const {
        data: opdData,
        scrollContainerRef: opdScrollContainerRef,
        isLoading: isOPDLoading,
        fetchData: fetchOPD,
    } = useFetchData(fetchOPDData, [dispatch]);

    // Fetch initial data on tab change to fifth
    useEffect(() => {
        fetchOPD(1); // Fetch OPD data when the fifth tab is selected
    }, []);


    // This function will handle tab changes and log the selected key
    const handleTabSelect = (selectedKey) => {
        setKey(selectedKey);
        sessionStorage.setItem("selectedTab", selectedKey); // Save selected key to localStorage
    };

    const V2HREF = localStorage.getItem("V2HREF")

    return (
        <div className="home-review-banner">
            <div className="home-app-header order-details-repo-data">
                <div className="header-logo">
                    <div className="user-name text-center text-repoo">
                        <p>Order Details</p>
                    </div>
                    {!V2HREF && V2HREF === null && (
                        <div className="order-home-repo">
                            <NavLink to={'/home'}>
                                <IoHome />
                            </NavLink>
                        </div>
                    )}
                </div>
            </div>

            <div className="tab-main-cover-wrapper">
                <Tab.Container id="left-tabs-example-repo-cont" activeKey={key} onSelect={handleTabSelect}>
                    <Row>
                        <Col lg={12} className="mb-2 tab-cover order-details-tab">
                            <Swiper
                                spaceBetween={10}
                                slidesPerView={2.5}
                                pagination={{ clickable: true }}
                                modules={[Navigation]}
                                className="order-tab-swiper"
                            >
                                <SwiperSlide>
                                    <Nav variant="pills" className="flex-column">
                                        <Nav.Item>
                                            <Nav.Link eventKey="first">
                                                Medicine Order
                                            </Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <Nav variant="pills" className="flex-column">
                                        <Nav.Item>
                                            <Nav.Link eventKey="second">
                                                Lab Tests
                                            </Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <Nav variant="pills" className="flex-column">
                                        <Nav.Item>
                                            <Nav.Link eventKey="third">
                                                Teleconsultation
                                            </Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <Nav variant="pills" className="flex-column">
                                        <Nav.Item>
                                            <Nav.Link eventKey="forth">
                                                Radiology
                                            </Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <Nav variant="pills" className="flex-column">
                                        <Nav.Item>
                                            <Nav.Link eventKey="fifth">
                                                OPD
                                            </Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </SwiperSlide>
                            </Swiper>
                        </Col>

                        <Col lg={12}>
                            <Tab.Content>
                                <Tab.Pane eventKey="first">
                                    <div className="medicine-cover-data-wrapper-boxs-cover">
                                        {orderHistoryData?.status === "loading" ? (
                                            <MediLoader height={height} />
                                        ) : (
                                            <div className="medicine-order-details-cover">
                                                <Order_Card OrderData={orderHistoryData} />
                                            </div>
                                        )}
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="second">
                                    <div className="medicine-cover-data-wrapper-boxs-cover">
                                        {orderLabHistoryData?.status === "loading" ? (
                                            <MediLoader height={height} />
                                        ) : (
                                            <div className="medicine-order-details-cover">
                                                <LabOrderCard OrderData={orderLabHistoryData} Tabkey={key} />
                                            </div>
                                        )}
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="third">
                                    <div className="medicine-cover-data-wrapper-boxs-cover">
                                        {orderTeleconsultationHistoryData?.status === "loading" ? (
                                            <MediLoader height={height} />
                                        ) : (
                                            <div className="medicine-order-details-cover">
                                                <TeleconsultationOrderCard OrderData={orderTeleconsultationHistoryData} Tabkey={key} />
                                            </div>
                                        )}
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="forth">
                                    <div className="medicine-cover-data-wrapper-boxs-cover">
                                        {orderTeleconsultationHistoryData?.status === "loading" ? (
                                            <MediLoader height={height} />
                                        ) : (
                                            <div className="medicine-order-details-cover">
                                                <RadiologyOrderCard OrderData={orderRadioHistoryData} Tabkey={key} />
                                            </div>
                                        )}
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="fifth">
                                    <div className="medicine-cover-data-wrapper-boxs-cover">
                                        {orderTeleconsultationHistoryData?.status === "loading" ? (
                                            <MediLoader height={height} />
                                        ) : (
                                            <div className="medicine-order-details-cover">
                                                <DoctorConsultationCard OrderData={orderDoctorHistoryData} Tabkey={key} />
                                            </div>
                                        )}
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </div>
        </div>
    );
};

export default OrderDetailsPage;
