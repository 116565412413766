import React, { useEffect, useState } from 'react'
import { appOPDIcon, headerLogo } from '../../images'
import { validateNumberInput } from '../../utils/AllFunction'
import { genderList, relationshipList } from '../../data'
import { useForm } from 'react-hook-form'
import { MdSchool } from 'react-icons/md'
import { FaBuilding } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { fetchPackageList } from '../../Redux/actions/GetApiCall'
import { useNavigate } from 'react-router'
import { doctorpatientform } from '../../Redux/actions/PostApiCall'
import LoaderAnime from '../Medicine/animation/prescription_loader'
import useLocationState from '../Medicine/hooks/useLocationState'
import toast from 'react-hot-toast'
import ToastBox from '../Medicine/animation/ToastBox'
const DoctorForm = () => {
    const { register, handleSubmit, formState: { errors },watch} = useForm();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [modalShow, setModalShow] = useState(false);
    const DoctorDetailData = useLocationState();
    console.log(DoctorDetailData)
    const package_list = useSelector((state) => state?.package_list);
    const doctor_patient_form = useSelector((state) => state?.doctor_patient_form);
    console.log(doctor_patient_form)

    useEffect(() => {
        dispatch(fetchPackageList())
    }, [dispatch])

    const onSubmit = async (data) => {
        setModalShow(true)
        const onDataSubmit = {
            department_id: DoctorDetailData?.departmentType?.length > 1 ? data?.department : DoctorDetailData?.departmentType?.[0]?.department_id,
            doctor_id: DoctorDetailData?.doctor_id,
            appointment_date: DoctorDetailData?.online_date || DoctorDetailData?.inperson_date,
            appointment_time: DoctorDetailData?.Online_time || DoctorDetailData?.inperson_time,
            panel_type: 2,
            panel_name: DoctorDetailData?.panelName === "Online Consultation" ? 1 : 2,
            patient_name: data?.patient_name,
            age: data?.patient_age,
            age_type: data?.age_type,
            gender: data?.gender?.toLowerCase(),
            contact: package_list?.data?.profile_details?.mobile_number,
            email: package_list?.data?.profile_details?.email_id,
            relation: data?.relation,
            hospital_id: DoctorDetailData?.hospitalId
        };

        console.log(onDataSubmit)
        await dispatch(doctorpatientform(onDataSubmit))
    }

    useEffect(() => {
        if (doctor_patient_form?.status === "success") {
            setModalShow(false)
            const data = {
                id: doctor_patient_form?.data?.data?.consultation?.id,
                panelType: DoctorDetailData?.panelType,
                doctorData: DoctorDetailData?.DoctorData
            }
            navigate("/doctor-order", { state: data })
        }
        else if (doctor_patient_form?.status === "failure") {
            setModalShow(false)
            toast.error(doctor_patient_form?.error)
        }
    }, [doctor_patient_form])

    useEffect(() => {
        if (package_list?.status === "loading") {
            setModalShow(true)
        } else {
            setModalShow(false)
        }
    }, [package_list])
    // const handleOrder = () => {
    //     navigate("/doctor-order")
    // }
    return (
        <div className="home-review-banner">
            {modalShow && <LoaderAnime showModal={modalShow} />}
            <div className="home-app-header d-flex justify-content-start align-items-center">

                <div className="header-logo mx-3">
                    <img
                        src={headerLogo}
                        className="header-medibhai-logo"
                        width="100%"
                        alt="header-logo"
                    />
                    <p className='opd-text'>Patient Details</p>
                </div>
            </div>
            <div className="opd-wrapper-cover-data mt-4">
                <div className="container">
                    <div className="doctor-detail-repo-corner">
                        <div className="doctor-data-repo" style={{ marginTop: '0px' }}>
                            <div className="doctor-tepo-image">
                                <img src={appOPDIcon} alt="" />
                                <div className="dot"></div>
                            </div>
                            <div className="doctor-data-box-tepo">
                                <h5 style={{ marginBottom: '0px' }}>{DoctorDetailData?.DoctorData?.doctor_name}</h5>
                                <p style={{ fontSize: "11px", color: "#757575", marginBottom: '5px' }}>{DoctorDetailData?.DoctorData?.departments?.[0]?.department_name}</p>
                                <p className="doctor-category"><MdSchool />{DoctorDetailData?.DoctorData?.education}</p>
                                <p className="doctor-category"><FaBuilding /> {DoctorDetailData?.DoctorData?.address}</p>
                            </div>
                        </div>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="heading-data-form">
                            {/* <p>Get in person consultation reimbursement up to 5000</p> */}
                        </div>
                        <div className="form-data-repo-box mt-3">
                            <input type="text" value={package_list?.data?.profile_details?.mobile_number} disabled />
                            <input type="text" value={package_list?.data?.profile_details?.email_id} disabled />
                        </div>
                        <div className="form-page-data mt-2">
                            <div className="page-heading-text">
                                <p>Patient Details</p>
                            </div>

                            <div className="form-page">
                                <input type="text" placeholder='Enter Patient Name' {...register('patient_name', { required: true })} />
                                {errors.patient_name && <span className='error-text-mssg'>This field is Required</span>}
                                {DoctorDetailData?.departmentType?.length > 1 && (
                                    <div className="option-data-page">
                                        <select
                                            style={{ width: "100%" }}
                                            name="department"
                                            {...register('department', { required: true })}>
                                            <option value="" selected disabled>Select Department</option>
                                            {DoctorDetailData?.departmentType?.map((data, index) => (
                                                <option key={index} value={data?.department_id}>{data?.department_name}</option>

                                            ))}
                                        </select>
                                        {errors.department && <span className='error-text-mssg gen-text'>Department is required</span>}
                                    </div>
                                )}
                                <div className="option-data-page">
                                    <div className="gender-select-page">
                                        <select
                                            name="gender"
                                            {...register('gender', { required: true })}>
                                            <option value="" selected disabled>Select Gender</option>
                                            {genderList.map((data, index) => (
                                                <option key={index} value={data?.text}>{data?.text}</option>

                                            ))}
                                        </select>
                                        {errors.gender && <span className='error-text-mssg gen-text'>Gender is required</span>}
                                    </div>
                                    <div className="gender-select-page">
                                        <select
                                            name="relation"
                                            {...register('relation', { required: true })}>
                                            <option value="">Select Relation</option>
                                            {relationshipList.map((data, index) => (
                                                <option key={index} value={data?.text}>{data?.text}</option>
                                            ))}
                                        </select>
                                        {errors.relation && <span className='error-text-mssg gen-text'>Relation is required</span>}
                                    </div>
                                </div>
                                <div className="option-data-page">
                                    <div className="gender-select-page">
                                        <input
                                            type="text"
                                            placeholder="Enter Patient Age"
                                            onInput={validateNumberInput}
                                            maxLength={3}
                                            {...register("patient_age", {
                                                required: "Age is required",
                                                validate: (value) => {
                                                    if (watch("age_type") === "years" && value > 120) {
                                                        return "Age in years must be 120 or less";
                                                    }
                                                    return true;
                                                },
                                            })}
                                        />
                                        {errors.patient_age && (
                                            <span className="error-text-mssg">{errors.patient_age.message}</span>
                                        )}
                                    </div>
                                    <div className="gender-select-page">
                                        <select
                                            {...register("age_type", {
                                                required: "Age type is required",
                                            })}
                                            defaultValue="" // sets default selected value
                                        >
                                            <option value="" disabled>
                                                Select Age Type
                                            </option>
                                            <option value="days">Days</option>
                                            <option value="months">Months</option>
                                            <option value="years">Years</option>
                                        </select>

                                        <span className="error-text-mssg gen-text">
                                            {errors.age_type && errors.age_type.message}
                                        </span>
                                    </div>
                                </div>


                            </div>
                        </div>

                        <div className="submit-btn-wrap">
                            <button>Submit</button>
                        </div>
                    </form>
                </div>
            </div>
            <ToastBox />
        </div>
    )
}

export default DoctorForm