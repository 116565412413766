import React from 'react';
import { headerLogo } from '../images';
import useLocationState from '../Pages/Medicine/hooks/useLocationState';
import { useNavigate } from 'react-router';
import { MdArrowRightAlt } from 'react-icons/md';

const AllPackages = () => {

    const PackageData = useLocationState();
    const navigate = useNavigate();
    const handlePackageDetails = (id) => {
        navigate('/packagedetailbox', { state: id })
    }

    return (
        <div className="home-review-banner">
            {/* Header */}
            <div className="home-app-header sticky-top">
                <div className="header-logo">
                    <img src={headerLogo} width="100%" alt="header-logo" />
                    <div className="user-name-text-repo">
                        <p style={{ fontSize: '16px', marginLeft: '10px' }}>My Packages</p>
                    </div>
                </div>
            </div>

            <div className="all-package-repo-cola" style={{ paddingLeft: '15px' }}>
                {PackageData?.length > 0 ? (
                    PackageData?.map((packageBox, index) => {
                        const sanitizedBanner = packageBox?.banner;
                        return (
                            <div
                                onClick={() => handlePackageDetails(packageBox?.id)}
                                className="home-section-banner p-0 mt-2"

                            >
                                <img src={packageBox?.banner} alt="" />
                                {packageBox?.package_text_visibility === 0 && (
                                    <div className="container">
                                        <div className="home-section-main-banner">
                                            <div className="row">
                                                <div className="col-lg-8 col-8">
                                                    <div className="banner-left-side">
                                                        <div className="left-image-box">
                                                            <p dangerouslySetInnerHTML={{ __html: packageBox?.description }}></p>
                                                        </div>
                                                        <div className="konw-more-btn">
                                                            <a href="#">
                                                                <p>
                                                                    Know more <MdArrowRightAlt />
                                                                </p>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-4 pe-0">
                                                    <div className="banner-right-side" style={{marginTop:"-92px"}}>
                                                        <div className="package-title">
                                                            <p>{packageBox?.name}</p>
                                                        </div>
                                                        <div className="package-date">
                                                            <p>
                                                                package Valid Till{" "}
                                                                <span>{packageBox?.expiring_on}</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        );
                    })
                ) : (
                    <p>No packages available</p>
                )}
            </div>

        </div>
    );
};

export default AllPackages;
