import {
  DentalIcon,
  HospitalIcon,
  LabTestIcon,
  MedicalloanIcon,
  MedicinesIcon,
  OPDIcon,
  RadiologyIcon,
  TeleconsultationIcon,
  appDignosticsupport,
} from "./images";

import {
  FaEye,
  FaTooth,
  FaHeartbeat,
  FaBone,
  FaBrain,
  FaSyringe,
  FaBaby,
  FaRibbon,
  FaUserMd,
  FaXRay,
  FaFemale,
} from "react-icons/fa";

export const WellnessSixModules = [
  {
    app_index_id: 3,
    show_hide: 1,
    name: "Buy\nMedicines",
    image: MedicinesIcon,
  },
  {
    app_index_id: 1,
    show_hide: 1,
    name: "Diagnostic\nTest",
    image: LabTestIcon,
  },
  {
    app_index_id: 2,
    show_hide: 1,
    name: "Tele\nconsultation",
    image: TeleconsultationIcon,
  },
  {
    app_index_id: 4,
    show_hide: 1,
    name: "OPD\nConsultation",
    image: OPDIcon,
  },
  // {
  //     "app_index_id": 6,
  //     "show_hide": 1,
  //     "name": "Medical\nLoan",
  //     "image": MedicalloanIcon
  // },
  {
    app_index_id: 7,
    show_hide: 1,
    name: "Hospital\nConsultation",
    image: HospitalIcon,
  },
  // {
  //   app_index_id: 8,
  //   show_hide: 1,
  //   name: "Radiology\nScreening",
  //   image: RadiologyIcon,
  // },
];

export const WellnessCorner = [
  {
    app_index_id: 1,
    show_hide: 1,
    name: "Fitness",
    image: "https://medibhai.com/assets/images/medibhai_images/fitness.svg",
  },
  {
    app_index_id: 2,
    show_hide: 1,
    name: "Yoga",
    image: "https://medibhai.com/assets/images/medibhai_images/yoga.svg",
  },
  {
    app_index_id: 3,
    show_hide: 1,
    name: "Weight Loss",
    image: "https://medibhai.com/assets/images/medibhai_images/weight-loss.svg",
  },
  {
    app_index_id: 4,
    show_hide: 1,
    name: "Nutrition",
    image: "https://medibhai.com/assets/images/medibhai_images/nutrition.svg",
  },
  {
    app_index_id: 5,
    show_hide: 1,
    name: "Life Style",
    image: "https://medibhai.com/assets/images/medibhai_images/Life_Style.svg",
  },
];

export const genderList = [
  { value: "1", text: "Male" },
  { value: "2", text: "Female" },
  { value: "3", text: "Others" },
];

export const relationshipList = [
  { value: "1", text: "Self" },
  { value: "2", text: "Spouse" },
  { value: "3", text: "Father" },
  { value: "4", text: "Mother" },
  // { value: "6", text: "Brother" },
  // { value: "7", text: "Sister" },
  { value: "9", text: "Son" },
  { value: "10", text: "Daughter" },
];
export const relationshipOPDList = [
  { value: "1", text: "Self" },
  { value: "2", text: "Spouse" },
  { value: "3", text: "Father" },
  { value: "4", text: "Mother" },
  // { value: "6", text: "Brother" },
  // { value: "7", text: "Sister" },
  { value: "9", text: "Son" },
  { value: "10", text: "Daughter" },
];

export const WalletTransactionList = [
  {
    id: 1,
    value: "All",
  },
  {
    id: 2,
    value: "Medicine",
  },
  {
    id: 3,
    value: "Diagnostics",
  },
  {
    id: 4,
    value: "Teleconsultation",
  },
  {
    id: 5,
    value: "Fitness",
  },
  {
    id: 6,
    value: "OPD",
  },
  {
    id: 7,
    value: "Dental",
  },
  {
    id: 8,
    value: "Eye",
  },
];

export const DoctorData = [
  {
    name: "Dr. B.S. Yadav",
    category: "General Physician",
    education: "MBBS, MD - General Medicine",
    Fees: "₹ 900",
    hospital_name: "Motherhi Department Of Paediatrics & Neonatology",
    city: "Bandra (W)",
    description:
      "Dr. B.S. Yadav is a dedicated General Physician with an MBBS and MD in General Medicine. He focuses on providing comprehensive healthcare for patients of all ages, emphasizing preventive care and health education. With extensive experience in various hospitals, he believes in personalized treatment plans to ensure optimal health for his patients.",
  },
  {
    name: "Dr. Pankaj Yadav",
    category: "General Physician",
    education: "MBBS, MD - General Medicine",
    Fees: "₹ 900",
    hospital_name: "Motherhi Department Of Paediatrics & Neonatology",
    city: "Bandra (W)",
    description:
      "Dr. B.S. Yadav is a dedicated General Physician with an MBBS and MD in General Medicine. He focuses on providing comprehensive healthcare for patients of all ages, emphasizing preventive care and health education. With extensive experience in various hospitals, he believes in personalized treatment plans to ensure optimal health for his patients.",
  },
  {
    name: "Dr. A.K. Sharma",
    category: "Cardiologist",
    education: "MBBS, DM - Cardiology",
    hospital_name: "Heart Care Clinic",
    city: "Andheri (E)",
    Fees: "₹ 400",
    description:
      "Dr. A.K. Sharma is a leading Cardiologist specializing in heart-related conditions. He holds an MBBS and DM in Cardiology, with years of experience in cardiac care. Dr. Sharma emphasizes preventive cardiology and collaborates with patients to manage risk factors, utilizing advanced diagnostic tools for comprehensive evaluations.",
  },
  {
    name: "Dr. S.L. Gupta",
    category: "Orthopedic Surgeon",
    education: "MBBS, MS - Orthopaedics",
    hospital_name: "City Orthopedic Hospital",
    city: "Malad (W)",
    Fees: "₹ 500",
    description:
      "Dr. S.L. Gupta is a skilled Orthopedic Surgeon specializing in musculoskeletal issues. With an MBBS and MS in Orthopaedics, he has successfully performed numerous surgeries, including joint replacements. He is committed to restoring mobility and improving the quality of life for his patients through personalized rehabilitation plans.",
  },
  {
    name: "Dr. Nisha Mehta",
    category: "Dermatologist",
    education: "MBBS, MD - Dermatology",
    hospital_name: "Skin & Hair Clinic",
    city: "Juhu",
    Fees: "₹ 900",
    description:
      "Dr. Nisha Mehta is a highly regarded Dermatologist with an MBBS and MD in Dermatology. She specializes in treating various skin conditions and cosmetic dermatology, emphasizing the importance of early diagnosis. At Skin & Hair Clinic, she offers personalized care and a range of cosmetic procedures.",
  },
  {
    name: "Dr. Pankaj Verma",
    category: "Neurologist",
    education: "MBBS, DM - Neurology",
    hospital_name: "Neuro Care Hospital",
    city: "Borivali (W)",
    Fees: "₹ 970",
    description:
      "Dr. Pankaj Verma is a prominent Neurologist specializing in diagnosing and treating neurological disorders. With an MBBS and DM in Neurology, he has extensive experience managing conditions like epilepsy and migraines. Dr. Verma utilizes advanced diagnostic tools and prioritizes patient education.",
  },
  {
    name: "Dr. Priya Kaur",
    category: "Gynecologist",
    education: "MBBS, MD - Obstetrics & Gynaecology",
    hospital_name: "Women’s Health Clinic",
    city: "Kandivali (E)",
    Fees: "₹ 9000",
    description:
      "Dr. Priya Kaur is a respected Gynecologist dedicated to women’s health, holding an MBBS and MD in Obstetrics & Gynaecology. With a focus on prenatal care and reproductive health, she provides compassionate care and education to her patients throughout their life stages at Women’s Health Clinic.",
  },
  {
    name: "Dr. Rohit Desai",
    category: "Pediatrician",
    education: "MBBS, MD - Pediatrics",
    hospital_name: "Child Care Clinic",
    city: "Goregaon (W)",
    Fees: "₹ 8400",
    description:
      "Dr. Rohit Desai is a dedicated Pediatrician specializing in the health and well-being of children. He holds an MBBS and MD in Pediatrics and has experience managing both common and complex pediatric conditions. Dr. Desai emphasizes preventive care and works closely with parents to ensure optimal health for their children.",
  },
  {
    name: "Dr. Sunita Patel",
    category: "Endocrinologist",
    education: "MBBS, DM - Endocrinology",
    hospital_name: "Diabetes Care Center",
    city: "Vile Parle (E)",
    Fees: "₹ 6400",
    description:
      "Dr. Sunita Patel is an expert Endocrinologist specializing in hormonal imbalances and metabolic disorders. With an MBBS and DM in Endocrinology, she manages conditions such as diabetes and thyroid issues. Dr. Patel prioritizes patient education and lifestyle modifications for better health outcomes.",
  },
  {
    name: "Dr. Anil Jadhav",
    category: "Gastroenterologist",
    education: "MBBS, DM - Gastroenterology",
    hospital_name: "Gastro Care Hospital",
    city: "Dadar (W)",
    Fees: "₹ 9000",
    description:
      "Dr. Anil Jadhav is a skilled Gastroenterologist specializing in digestive system disorders. With an MBBS and DM in Gastroenterology, he is well-versed in both diagnostic and therapeutic endoscopy. Dr. Jadhav believes in a multidisciplinary approach to provide holistic care for his patients.",
  },
  {
    name: "Dr. Meera Kapoor",
    category: "Ophthalmologist",
    education: "MBBS, MS - Ophthalmology",
    hospital_name: "Eye Care Clinic",
    city: "Santacruz (E)",
    Fees: "₹ 9520",
    description:
      "Dr. Meera Kapoor is an accomplished Ophthalmologist specializing in eye health. She completed her MBBS and MS in Ophthalmology and has treated numerous patients with cataracts and refractive errors. Dr. Kapoor emphasizes early detection and regular eye examinations at Eye Care Clinic.",
  },
];

export const HospitalData = [
  {
    hospital_name: "City General Hospital",
    city: "Mumbai",
    address: "123 Marine Drive, Mumbai, Maharashtra, 400002",
    number_of_departments: 2,
    departments: ["Cardiology", "Neurology"],
    ratings: 4.5,
    speciality: "Emergency and Cardiovascular Care",
    important_data: "24/7 Emergency Services Available",
    about:
      "City General Hospital is a leading healthcare provider in Mumbai, known for its advanced cardiac care and emergency services. The hospital features state-of-the-art facilities and a team of highly skilled professionals dedicated to providing exceptional medical care.",
    timings: {
      days: "Open 24 Hours",
      time: "",
    },
  },
  {
    hospital_name: "Riverside Medical Center",
    city: "Delhi",
    address: "456 Riverside Road, Delhi, 110011",
    number_of_departments: 2,
    departments: ["Pediatrics", "Orthopedics"],
    ratings: 4.2,
    speciality: "Pediatric and Orthopedic Care",
    important_data: "Advanced Surgical Care Units",
    about:
      "Riverside Medical Center specializes in pediatric and orthopedic care, offering a wide range of services from routine check-ups to complex surgeries. Their commitment to child-friendly care and innovative treatments makes them a trusted choice for families.",
    timings: {
      days: "Mon-Sat",
      time: "8 AM - 8 PM",
    },
  },
  {
    hospital_name: "Northern Regional Hospital",
    city: "Bangalore",
    address: "789 North Avenue, Bangalore, Karnataka, 560001",
    number_of_departments: 2,
    departments: ["Cardiology", "Oncology"],
    ratings: 4.7,
    speciality: "Cancer and Heart Treatments",
    important_data: "Specialized in Cancer Treatments",
    about:
      "Northern Regional Hospital is recognized for its comprehensive cancer treatment programs and advanced cardiac care. Their multidisciplinary teams work collaboratively to provide personalized treatment plans tailored to each patient's needs.",
    timings: {
      days: "Mon-Sun",
      time: "9 AM - 9 PM",
    },
  },
  {
    hospital_name: "Bayview Health Clinic",
    city: "Chennai",
    address: "101 Bayview Road, Chennai, Tamil Nadu, 600032",
    number_of_departments: 2,
    departments: ["Family Medicine", "Dermatology"],
    ratings: 4.3,
    speciality: "Family and Dermatology Care",
    important_data: "Comprehensive Family Health Services",
    about:
      "Bayview Health Clinic offers a holistic approach to healthcare, providing family medicine and dermatological services. Their focus on preventive care and patient education ensures families receive quality care in a welcoming environment.",
    timings: {
      days: "Mon-Sat",
      time: "10 AM - 6 PM",
    },
  },
  {
    hospital_name: "Metro Hospital & Research Center",
    city: "Kolkata",
    address: "234 Metro Lane, Kolkata, West Bengal, 700001",
    number_of_departments: 2,
    departments: ["Neurology", "Gastroenterology"],
    ratings: 4.6,
    speciality: "Neurology and Gastroenterology Research",
    important_data: "State-of-the-Art Research Facilities",
    about:
      "Metro Hospital & Research Center is at the forefront of medical research, particularly in neurology and gastroenterology. With cutting-edge technology and experienced staff, they provide specialized treatments and contribute to advancements in medical science.",
    timings: {
      days: "Mon-Fri",
      time: "8 AM - 10 PM",
    },
  },
  {
    hospital_name: "Valley Children's Hospital",
    city: "Hyderabad",
    address: "567 Valley Street, Hyderabad, Telangana, 500032",
    number_of_departments: 2,
    departments: ["Pediatrics", "Neonatology"],
    ratings: 4.8,
    speciality: "Pediatric and Neonatal Intensive Care",
    important_data: "Specialized Neonatal Intensive Care Unit",
    about:
      "Valley Children's Hospital is dedicated to the health of children, offering exceptional pediatric and neonatal care. Their team of specialists is focused on providing a nurturing environment for young patients and their families.",
    timings: {
      days: "Open 24 Hours",
      time: "N/A",
    },
  },
  {
    hospital_name: "Coastal Community Hospital",
    city: "Ahmedabad",
    address: "678 Coastal Road, Ahmedabad, Gujarat, 380001",
    number_of_departments: 2,
    departments: ["Cardiology", "Pulmonology"],
    ratings: 4.4,
    speciality: "Cardiac and Pulmonary Care",
    important_data: "Cardiac Emergency Services Available",
    about:
      "Coastal Community Hospital provides specialized care in cardiology and pulmonology, emphasizing community health and wellness. Their experienced staff and modern facilities ensure patients receive top-notch medical attention.",
    timings: {
      days: "Mon-Sun",
      time: "24 Hours",
    },
  },
  {
    hospital_name: "Eastside Medical Center",
    city: "Pune",
    address: "890 Eastside Avenue, Pune, Maharashtra, 411001",
    number_of_departments: 2,
    departments: ["Orthopedics", "ENT"],
    ratings: 4.1,
    speciality: "Orthopedic and ENT Care",
    important_data: "Advanced Orthopedic Care and Surgeries",
    about:
      "Eastside Medical Center is known for its expertise in orthopedic and ENT services. They offer a wide range of surgical and non-surgical treatments aimed at restoring health and enhancing the quality of life for their patients.",
    timings: {
      days: "Mon-Sat",
      time: "9 AM - 9 PM",
    },
  },
  {
    hospital_name: "Southview Women's Hospital",
    city: "Jaipur",
    address: "345 Southview Road, Jaipur, Rajasthan, 302001",
    number_of_departments: 2,
    departments: ["Obstetrics", "Gynecology"],
    ratings: 4.9,
    speciality: "Maternity and Women's Health Care",
    important_data: "Specialized Maternity Care Services",
    about:
      "Southview Women's Hospital is a premier facility focusing on women's health, providing a comprehensive range of services from prenatal to postnatal care. Their compassionate staff supports women through every stage of life.",
    timings: {
      days: "Mon-Sun",
      time: "9 AM - 8 PM",
    },
  },
  {
    hospital_name: "Summit Health Hospital",
    city: "Surat",
    address: "234 Summit Boulevard, Surat, Gujarat, 395003",
    number_of_departments: 2,
    departments: ["Urology", "Dermatology"],
    ratings: 4.2,
    speciality: "Skin and Urological Care",
    important_data: "Dermatology and Skin Care Experts",
    about:
      "Summit Health Hospital combines advanced urology and dermatology services, offering patients a holistic approach to their health needs. They are committed to providing personalized care and innovative treatment options.",
    timings: {
      days: "Mon-Sun",
      time: "8 AM - 10 PM",
    },
  },
  {
    hospital_name: "Green Valley Medical Center",
    city: "Lucknow",
    address: "567 Green Valley Road, Lucknow, Uttar Pradesh, 226001",
    number_of_departments: 2,
    departments: ["Psychiatry", "Neurology"],
    ratings: 4.5,
    speciality: "Mental Health and Neurological Care",
    important_data: "Mental Health and Neurological Care",
    about:
      "Green Valley Medical Center specializes in mental health and neurological disorders, providing comprehensive services that include assessment, treatment, and rehabilitation, ensuring patients receive the support they need.",
    timings: {
      days: "Mon-Fri",
      time: "9 AM - 8 PM",
    },
  },
  {
    hospital_name: "Heartland Medical Center",
    city: "Kanpur",
    address: "789 Heartland Street, Kanpur, Uttar Pradesh, 208001",
    number_of_departments: 2,
    departments: ["Cardiology", "Nephrology"],
    ratings: 4.7,
    speciality: "Heart and Kidney Care",
    important_data: "Advanced Heart and Kidney Care",
    about:
      "Heartland Medical Center is dedicated to treating heart and kidney diseases, offering state-of-the-art technology and expert care. They focus on patient-centered approaches to improve health outcomes.",
    timings: {
      days: "Mon-Sun",
      time: "24 Hours",
    },
  },
  {
    hospital_name: "Silver Lake Hospital",
    city: "Nagpur",
    address: "901 Silver Lake Road, Nagpur, Maharashtra, 440001",
    number_of_departments: 2,
    departments: ["Orthopedics", "Radiology"],
    ratings: 4.0,
    speciality: "Orthopedics and Radiology",
    important_data: "Specialized in Diagnostic Imaging",
    about:
      "Silver Lake Hospital provides specialized orthopedic and radiology services, ensuring patients receive accurate diagnoses and effective treatments. Their commitment to quality care makes them a trusted healthcare provider.",
    timings: {
      days: "Mon-Sun",
      time: "10 AM - 6 PM",
    },
  },
  {
    hospital_name: "Harmony Health Hospital",
    city: "Indore",
    address: "234 Harmony Lane, Indore, Madhya Pradesh, 452001",
    number_of_departments: 2,
    departments: ["Family Medicine", "Pediatrics"],
    ratings: 4.4,
    speciality: "Family and Children's Health",
    important_data: "Comprehensive Family Health Services",
    about:
      "Harmony Health Hospital offers a family-centered approach to healthcare, focusing on both adult and pediatric care. Their experienced staff is committed to providing a nurturing environment for all patients.",
    timings: {
      days: "Mon-Fri",
      time: "9 AM - 9 PM",
    },
  },
];

export const HospitalCategories = [
  {
    name: "Eye Surgeon",
    department: "Ophthalmology",
    bgColor: "#f7eaea", // Cornsilk
    boxColor: "#ff8282", // Gold for text/border
    icon: <FaEye />,
  },
  {
    name: "Dental Surgeon",
    department: "Dentistry",
    bgColor: "#e9eef7", // Light Red
    boxColor: "#5397fd", // Dark Red for text/border
    icon: <FaTooth />,
  },
  {
    name: "Heart Surgeon",
    department: "Cardiology",
    bgColor: "#FFEBEE", // Light Pink
    boxColor: "#fd9b71", // Dark Pink for text/border
    icon: <FaHeartbeat />,
  },
  {
    name: "Orthopedic Surgeon",
    department: "Orthopedics",
    bgColor: "#D1E7DD", // Light Greenish
    boxColor: "#00796B", // Dark Teal for text/border
    icon: <FaBone />,
  },
  {
    name: "Neurologist Surgeon",
    department: "Neurology",
    bgColor: "#EDE7F6", // Lavender
    boxColor: "#6A1B9A", // Dark Purple for text/border
    icon: <FaBrain />,
  },
  {
    name: "Dermatologist Surgeon",
    department: "Dermatology",
    bgColor: "#FFF0F5", // Lavender Blush
    boxColor: "#D5006D", // Dark Pink for text/border
    icon: <FaSyringe />,
  },
  {
    name: "Pediatrician Surgeon",
    department: "Pediatrics",
    bgColor: "#DFFFD6", // Light Mint
    boxColor: "#388E3C", // Dark Green for text/border
    icon: <FaBaby />,
  },
  {
    name: "Oncologist Surgeon",
    department: "Oncology",
    bgColor: "#FFEB3B", // Light Yellow
    boxColor: "#F57F20", // Dark Orange for text/border
    icon: <FaRibbon />,
  },
  {
    name: "General Surgeon",
    department: "General Surgery",
    bgColor: "#E0F7FA", // Light Cyan
    boxColor: "#0097A7", // Dark Cyan for text/border
    icon: <FaUserMd />,
  },
  {
    name: "Radiologist Surgeon",
    department: "Radiology",
    bgColor: "#B0BEC5", // Light Blue Grey
    boxColor: "#37474F", // Dark Blue Grey for text/border
    icon: <FaXRay />,
  },
  {
    name: "Gynecologist Surgeon",
    department: "Gynecology",
    bgColor: "#F8BBD0", // Light Pink
    boxColor: "#D81B60", // Dark Pink for text/border
    icon: <FaFemale />,
  },
];

export const HospitalFaq = [
  {
    question: "What are the visiting hours at the hospital?",
    answer:
      "Visiting hours are typically from 9:00 AM to 8:00 PM. However, they may vary by department. Please contact the specific department for precise timings.",
  },
  {
    question: "How do I schedule an appointment with a specialist?",
    answer:
      "You can schedule an appointment by calling our main number, using our online appointment system, or visiting the hospital's front desk during business hours.",
  },
  {
    question: "What should I bring for my hospital stay?",
    answer:
      "Please bring a valid ID, insurance information, a list of current medications, and any personal items such as toiletries and sleepwear. Avoid bringing valuables.",
  },
  {
    question: "Does the hospital accept my insurance?",
    answer:
      "We accept a variety of insurance providers. Contact our billing department or check our website for a list of accepted insurance plans.",
  },
  {
    question: "How can I obtain a copy of my medical records?",
    answer:
      "To request your medical records, contact our Health Information Management department. You'll need to fill out an authorization form and may be charged a nominal fee.",
  },
  {
    question: "Are there accommodations for family members of patients?",
    answer:
      "Yes, we provide accommodations for family members. We have a guest house nearby, and there are local hotels that offer discounts to hospital visitors.",
  },
  {
    question: "What should I do if I need emergency care?",
    answer:
      "In an emergency, please call 911 or go directly to our Emergency Department, which operates 24/7. Our team is equipped to handle all medical emergencies.",
  },
  {
    question: "Is there parking available at the hospital?",
    answer:
      "Yes, we have ample parking available for patients and visitors. Valet parking is also available at the main entrance for a small fee.",
  },
  {
    question: "How do I get a second opinion?",
    answer:
      "If you would like a second opinion, please inform your doctor or contact our patient services team. We can help arrange consultations with other specialists.",
  },
  {
    question: "What food options are available for patients and visitors?",
    answer:
      "Our hospital has a cafeteria that serves a variety of meals, and patients can request dietary-specific meals through our room service program.",
  },
  {
    question: "Are translation services available?",
    answer:
      "Yes, we offer translation services for many languages. Please let our staff know if you need assistance, and we'll arrange for an interpreter.",
  },
];

// export const x
