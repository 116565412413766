import React, { useEffect, useState } from "react";
import Medicine_Header from "../Components/Medicine_Header";
import "../Medicine_Style/home.css";
import {
  no_preview,
  Prescripti_Doctor_Icon,
  Prescription_Icon,
} from "../Images";
import Medicine_Search from "../Components/Medicine_Search";
import Medicine_Product from "../Components/Medicine_Product";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import Medicine_Recent from "../Components/Medicine_Recent";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchMedicineCartList,
  fetchMedicinelist,
  fetchMedicineRecent,
} from "../../../Redux/actions/GetApiCall";
import {
  useFetchMedicineList,
  useFetchMedicine,
} from "../../../QueryClient/MedicineQuery";
import { Controller, Pagination } from "swiper/modules";
import { useNavigate } from "react-router-dom";
import MediLoader from "../animation/MediLoader";
import Medicine_footer from "../Components/Medicine_footer";
import { appMedicineDelivery, appMedicinePackingOrder, appPackingOrder, appYourOrder } from "../../../images";

const Medicine_Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const key = "medicines"

  const {
    loader: mediloader,
    data: mediData,
    error: mediError,
  } = useSelector((state) => state.medicine_recent_list);
  const { data: cartData } = useSelector((state) => state.medicine_cart_list);
  const [cartListData, setCartListData] = useState([]);
  const pageNo = 1
  const [medicineData, setMedicineData] = useState([]);
  const AppSimulated = localStorage.getItem("is_simulated");

  const {
    data: medicineQueryData,
    error: medicineQueryError,
    isLoading: isLoadingMedicine,
  } = useFetchMedicine(AppSimulated);
  const {
    data: medicineList,
    isLoading: isLoadingList,
    error: medicineListError,
  } = useFetchMedicineList(medicineQueryData?.selected_cateogry?.value,AppSimulated);

  useEffect(() => {
    if (pageNo) {
      dispatch(fetchMedicineRecent(pageNo));
    }
    // dispatch(fetchMedicinelist());
    dispatch(fetchMedicineCartList())
  }, [dispatch]);

  useEffect(() => {
    if (medicineQueryData) {
      setMedicineData(medicineQueryData);
    }
  }, [medicineQueryData]);

  useEffect(() => {
    if (cartData) {
      setCartListData(cartData)
    }
  }, [cartData])

  const [firstSwiper, setFirstSwiper] = useState(null);
  const [secondSwiper, setSecondSwiper] = useState(null);

  const handleCorner = (item) => {
    const cornerData = [item];
    navigate("/health-corner", { state: cornerData });
  };

  const handlePresciption = () => {
    const HomeId = 1;
    navigate("/prescription", { state: HomeId });
  };

  const height = "25%";

  const handleBanner = (item) => {
    const data = {
      Title: "Product Details",
      data: {
        sku_id: item?.sku_id,
        type: "otc"
      }
    };
    if (item?.sku_id !== "" && item?.redirect_parameter === "") {
      navigate('/medicine-description', { state: data });
    }
    else if (item?.sku_id === "" && item?.redirect_parameter !== "") {
      const cornerData = [{
        value: item?.redirect_parameter,
        label: item?.redirect_parameter,
      }];
      navigate("/health-corner", { state: cornerData });
    }
  }

  const hanldeDescription = (item) => {
    const data = {
      Title: "Product Details",
      data: item
    };
    navigate('/medicine-description', { state: data });
  };

  const handleRecent = (item) => {
    const data = {
      Title: "Product Details",
      data: item
    };
    navigate('/medicine-description', { state: data });
  }

  const handleViewRecent = () => {
    navigate('/recent-products', { state: mediData });
  }



  return (
    <div className="home-review-banner">
      <Medicine_Header cartData={cartListData} />

      <div className="wrap-home-medicines-data">
        <Medicine_Search />

        <div className="data-pro-max-repo-prescription">
          <div className="prescription-repo-box-cover">
            <div
              className="prescription-box-data"
              onClick={() => handlePresciption()}
            >
              <div className="prescription-image-box-repo-img">
                <img src={Prescription_Icon} alt="prescription-icon" />
              </div>
              <div className="prescription-text-head">
                <p>Upload Prescription</p>
              </div>
            </div>
          </div>
        </div>



        <div className="categories-repo-box-data">
          <div className="categories-data">
            <p>Categories</p>
          </div>
        </div>



        {isLoadingMedicine ? (
          <>
            <MediLoader height={height} />
          </>
        ) : (
          <Medicine_Product medicineData={medicineData} />
        )}


        {mediData && mediData?.data?.length > 0 && (
          <>
            <div className="recent-text-repo-boxs">
              <div className="recent-text-box">
                <p>Recent Ordered Products</p>
              </div>
              <div className="view-all-repo-recent" onClick={() => handleViewRecent()}>
                <p>View All</p>
              </div>
            </div>

            <div className="medicine-slider-repo-box">
              <Swiper className="mySwiper" slidesPerView={4} spaceBetween={15}>
                {mediData &&
                  mediData?.data &&
                  mediData?.data?.map((item, index) => (
                    <SwiperSlide key={index} className="rect-repo-image" onClick={() => handleRecent(item)}>
                      <div className="image-box-slider-repo-recent">
                        <img src={item?.banner_url} alt="" />
                      </div>
                    </SwiperSlide>
                  ))}
              </Swiper>
            </div>

          </>
        )}

        {medicineData && medicineData?.medicine_banner?.length > 0 && (
          <div className="medicine-slider-repo-box">
            <Swiper className="mySwiper" slidesPerView={1.2}>
              {medicineData &&
                medicineData?.medicine_banner &&
                medicineData?.medicine_banner?.map((item, index) => (
                  <SwiperSlide key={index}>
                    <div className="image-box-slider-repo" onClick={() => handleBanner(item)}>
                      <img src={item?.url} alt="Banner-images" />
                    </div>
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
        )}

        {isLoadingList ? (
          <MediLoader height={height} />
        ) : (
          <div className="explore-categories-wrapper">
            <div className="explore-head-text-box">
              {medicineQueryData?.selected_cateogry?.label && (
                <p>Explore {medicineQueryData?.selected_cateogry?.label}</p>
              )}
            </div>

            <div className="explore-product-repo-box-wrapper">
              {medicineList &&
                medicineList?.data.slice(0, 4).map((item, index) => (
                  <div className="explore-product-repo-box-cover" key={index} onClick={() => hanldeDescription(item)}>
                    <div className="box-repo-data-content">
                      <img
                        src={item?.banner_url ? item?.banner_url : no_preview}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = no_preview;
                        }}
                        alt="product-icon"
                      />
                    </div>
                    <p className="ellipsis-text">{item?.name}</p>
                  </div>
                ))}
            </div>

            <div className="explore-btn-repo-box">
              <button
                onClick={() =>
                  handleCorner({
                    value: medicineQueryData?.selected_cateogry?.value,
                    label: medicineQueryData?.selected_cateogry?.label,
                  })
                }
              >
                <p>EXPLORE</p>
              </button>
            </div>
          </div>
        )}

        {medicineData && medicineData?.offer_banner?.length > 0 && (
          <>
            <div className="offer-text-repo-boxs">
              <div className="offer-text-box">
                <p>Exclusive Offers</p>
              </div>
            </div>

            <div className="medicine-slider-repo-box">
              <Swiper className="mySwiper" slidesPerView={1.25}>
                {medicineData &&
                  medicineData?.offer_banner &&
                  medicineData?.offer_banner?.map((item, index) => (
                    <SwiperSlide className="repo-offer-tepo">
                      <div className="image-box-slider-repo" onClick={() => handleBanner(item)}>
                        <img src={item?.url} alt="offer-images" />
                      </div>
                    </SwiperSlide>
                  ))}
              </Swiper>
            </div>
          </>
        )}


        {!isLoadingMedicine && (
          <div className="shop-wrapper-box-cover">
            <div className="shop-head-text">
              <h6>Shop by health Concerns</h6>
            </div>

            {isLoadingList ? (
              <MediLoader height={height} />
            ) : (
              <div className="shop-slider-repo-wrapper">
                <Swiper
                  className="mySwiper"
                  slidesPerView={2.5}
                  modules={[Controller]}
                  onSwiper={setFirstSwiper}
                  controller={{ control: secondSwiper }}
                >
                  {medicineData &&
                    medicineData?.health_concern &&
                    medicineData?.health_concern.slice(0, 5).map((item, index) => (
                      <SwiperSlide key={index} className="repo-conner">
                        <div
                          onClick={() =>
                            handleCorner({ value: item?.value, label: item?.label })
                          }
                          className="shop-image-box-slider-repo"
                          style={{ backgroundImage: `url(${item?.logo})` }}
                        ></div>
                      </SwiperSlide>
                    ))}
                </Swiper>
                <Swiper
                  className="mySwiper"
                  slidesPerView={2.5}
                  modules={[Controller]}
                  onSwiper={setSecondSwiper}
                  controller={{ control: firstSwiper }}
                >
                  {medicineData &&
                    medicineData?.health_concern &&
                    medicineData?.health_concern.slice(5, 10).map((item, index) => (
                      <SwiperSlide key={index} className="repo-conner">
                        <div
                          onClick={() =>
                            handleCorner({ value: item?.value, label: item?.label })
                          }
                          className="shop-image-box-slider-repo"
                          style={{ backgroundImage: `url(${item?.logo})` }}
                        ></div>
                      </SwiperSlide>
                    ))}
                </Swiper>
              </div>
            )}
          </div>
        )
        }

        <>
          <div className="how-its-work-section">
            <h3>How it works?</h3>
          </div>

          <Swiper
            spaceBetween={10}
            slidesPerView={1}
            modules={[Pagination]}
            pagination={{ clickable: true }}
            className="swiper-cover-repo mySwiper how-its-work-main-section"
          >
            <div className="swiper-wrapper">
              <SwiperSlide className="your-order-section swiper-wrap-repo">
                <div className="your-order-section-container">
                  <h3>Order Placed</h3>
                  <img src={appYourOrder} width="100%" alt="Order" />
                  <p>
                    Add products to your cart, enter your details and
                    confirm.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide className="your-order-section swiper-wrap-repo">
                <div className="your-order-section-container">
                  <h3>Picking Your Order</h3>
                  <img
                    src={appMedicinePackingOrder}
                    width="100%"
                    alt="Order"
                  />
                  <p>
                    Your order is being picked and now will be forward for
                    packaging
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide className="your-order-section swiper-slide swiper-wrap-repo">
                <div className="your-order-section-container">
                  <h3>Packing Your Order</h3>
                  <img
                    className="packing-order"
                    src={appPackingOrder}
                    width="100%"
                    alt="Order"
                  />
                  <p>
                    We are packing your order and will be out for delivery
                    soon.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide className="your-order-section swiper-slide swiper-wrap-repo">
                <div className="your-order-section-container">
                  <h3>Delivered</h3>
                  <img
                    className="packing-order"
                    src={appMedicineDelivery}
                    width="100%"
                    alt="Order"
                  />
                  <p>
                    Your order has been prepared and out for delivery.it
                    will be deliverd soon.
                  </p>
                </div>
              </SwiperSlide>
            </div>
            {/* <div className="swiper-pagination" /> */}
          </Swiper>
        </>
      </div>


      {
        cartListData.total_items > 0 && cartListData?.available_stock.length > 0 && (
          <Medicine_footer cartData={cartListData} tabkey={key} />
        )
      }
    </div>
  );
};

export default Medicine_Home;
