import React, { useEffect, useState } from 'react';
import { headerLogo } from '../../images';
import { useDispatch, useSelector } from 'react-redux';
import { fetchHospitalCategoriesList, fetchHospitalList } from '../../Redux/actions/GetApiCall';
import useLocationState from '../Medicine/hooks/useLocationState';
import { hospitalIcon } from '../Medicine/Images';
import MediLoader from '../Medicine/animation/MediLoader';
import { useNavigate } from 'react-router';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FiSearch } from 'react-icons/fi';

const HospitalCategories = () => {
    const ID = useLocationState();
    console.log(ID)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const hospitalData = useSelector(state => state.hospital_list);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedCategory, setSelectedCategory] = useState(ID?.name);
    const hospitalloader = useSelector(state => state.hospital_list.loader);
    const hospitalCategoriesData = useSelector(state => state.hospital_categories_list)
    const hospitalCategoriesloader = useSelector(state => state.hospital_categories_list.loader)

    useEffect(() => {
        dispatch(fetchHospitalList(ID?.id));
        dispatch(fetchHospitalCategoriesList())
    }, [dispatch, ID?.id]);


    const validHospitals = hospitalCategoriesData?.data?.data?.filter(hospital => hospital.name && hospital.name.trim() !== '');
    console.log(validHospitals)

    // Create a list of unique departments
    const uniqueDepartments = Array.from(
        new Set(
            validHospitals?.flatMap(hospital => {
                if (hospital?.name && hospital?.id) {
                    return [{ name: hospital.name, id: hospital.id }];
                }
                return [];
            })
        )
    );


    console.log(uniqueDepartments)

    // Filter hospitals based on search term and selected category


    const handleHospitalDetails = (item) => {
        navigate('/hospital-details', { state: item });
    };

    const handleCategoryChange = (department) => {
        dispatch(fetchHospitalList(department?.id));
        setSelectedCategory(department?.name)
    }

    const height = "75vh";

    const isDataEmpty = !hospitalData?.data?.data || hospitalData?.data?.data?.length === 0;

    return (
        <div className="home-review-banner">
            <div className="home-app-header sticky-top">
                <div className="header-logo">
                    <img src={headerLogo} width="100%" alt="header-logo" />
                    <div className="user-name-text-repo">
                        <p style={{ fontSize: '16px', marginLeft: '10px' }}>{ID?.name}</p>
                    </div>
                </div>
            </div>

            {hospitalloader && hospitalCategoriesloader ? (
                <MediLoader height={height} />
            ) : isDataEmpty ? (
                <p style={{
                    textAlign: 'center',
                    color: 'gray',
                    fontSize: '22px',
                    fontWeight: '500',
                    marginTop: '20px',
                    position: "relative",
                    top: "33%"
                }}>
                    {hospitalData?.data?.message}
                </p>
            ) : (
                <>
                    <div className="search-bar">
                        <input
                            type="text"
                            placeholder="Search hospital..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            onKeyPress={(e) => {
                                const regex = /^[a-zA-Z\s]*$/;
                                if (!regex.test(e.key)) {
                                    e.preventDefault();
                                }
                            }}
                        />
                        <FiSearch />
                        <div className="filter-buttons">
                            <Swiper spaceBetween={0} slidesPerView={2} effect="slide" grabCursor={true}>
                                <div className="swiper-wrapper">

                                    {uniqueDepartments.map((department, index) => (
                                        <SwiperSlide
                                            key={index}
                                            className={`repo-cate-doc ${selectedCategory === department?.name ? 'btn-active' : ''}`}
                                            onClick={() => handleCategoryChange(department)}
                                        >
                                            {department?.name}
                                        </SwiperSlide>
                                    ))}
                                </div>
                            </Swiper>
                        </div>
                    </div>
                    <div className="hospital-cover-repo-box mt-3" style={{ padding: '6px' }}>
                        {hospitalData?.data?.data?.map((item, index) => (
                            <div className="hospital-card-repo" key={index} onClick={() => handleHospitalDetails(item)}>
                                <img src={hospitalIcon} alt="hospital-image" />
                                <div className="hospital-data-wrap">
                                    <p>Name: <span>{item?.name}</span></p>
                                    <p style={{ color: 'GrayText' }}>City: <span>{item?.city}</span></p>
                                    <p style={{ color: 'GrayText' }}>Speciality: <span>{item?.speciality_type}</span></p>
                                    <p style={{ color: 'GrayText' }}>
                                        Doctors: <span>{item?.doctors?.length}</span>
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            )}
        </div>
    );
};

export default HospitalCategories;
