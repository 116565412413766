import React, { useEffect } from "react";
import { patientEdit, patientDelete, appPackageIcon } from "../../images";
import { useDispatch, useSelector } from "react-redux";
import { Toaster, toast } from "react-hot-toast";
import { radiodeletePatient } from "../../Redux/actions/PostApiCall";
import { fetchPatientsRadio } from "../../Redux/actions/GetApiCall";

const RadioPatientCard = ({
  setPatientCheckedData,
  cartDetailsList,
  patientsList,
  handleEditPatient,
  patientCheckedData,
}) => {
  const patientResponse = useSelector((state) => state.radio_delete_patient_members);
  const dispatch = useDispatch();


  const handleDeletepatient = async (patientData) => {
    await dispatch(radiodeletePatient(patientData));
    setPatientCheckedData((prev) => ({
      data: prev.data.filter((patient) => patient.member_id !== patientData?.member_id),
    }));
    await dispatch(fetchPatientsRadio());
  };

  const cartDetailsListLocal = JSON.parse(localStorage.getItem("selectedCenter"));
  const radioCityId = JSON.parse(localStorage.getItem("selectedRadiologyData"));

  // useEffect(() => {
  //   if (patientResponse?.status === "failure") {
  //     toast.error(patientResponse?.data?.message);
  //   }
  // }, [patientResponse]);

  const handlePatientCheckbox = (e, patientData) => {
    if (e.target.checked) {
      // Checkbox is checked
      setPatientCheckedData((prev) => {
        const existingPatientIndex = prev.data.findIndex(
          (pat_data) => pat_data.member_id === patientData?.id
        );

        if (existingPatientIndex !== -1) {
          // If the patient already exists, update their test_id and package_id arrays
          const updatedData = [...prev.data]; // Create a shallow copy of the data array
          const existingPatient = updatedData[existingPatientIndex];

          // Update or initialize test_id and package_id arrays as needed
          existingPatient.test_id = existingPatient.test_id || [];
          existingPatient.package_id = existingPatient.package_id || [];

          return { data: updatedData };
        } else {
          // If the patient does not exist, add a new entry
          return {
            data: [
              ...prev.data,
              {
                member_id: patientData?.id,
                lab_id: cartDetailsListLocal?.center_id,
                city_id: radioCityId?.selectedCityId,
                test_id: [],
                package_id: [],
              },
            ],
          };
        }
      });
    } else {
      // Checkbox is unchecked
      setPatientCheckedData((prev) => {
        // Remove the patient from the data
        return {
          data: prev.data.filter(
            (pat_data) => pat_data.member_id !== patientData?.id
          ),
        };
      });
    }
  };

  const handleMemberCheckbox = (e, cartData, patientData, type) => {
    if (e.target.checked) {
      setPatientCheckedData((prev) => {
        const existingPatientData = prev.data.find(
          (patient) => patient.member_id === patientData?.id
        );
        if (existingPatientData) {
          return {
            data: prev.data.map((patient) => {
              if (patient.member_id === patientData?.id) {
                return {
                  ...patient,
                  [type === "tests" ? "test_id" : "package_id"]: [
                    ...patient[type === "tests" ? "test_id" : "package_id"],
                    cartData.id,
                  ],
                };
              }
              return patient;
            }),
          };
        } else {
          // Update the existing patient data if it's not present
          const updatedData = prev.data.map((patient) => {
            if (patient.member_id === patientData?.id) {
              return {
                ...patient,
                [type === "tests" ? "test_id" : "package_id"]:
                  type === "tests" ? [cartData.id] : [cartData.id],
              };
            }
            return patient;
          });
          return { data: updatedData };
        }
      });
    } else {
      setPatientCheckedData((prev) => {
        return {
          data: prev.data.map((patient) => {
            if (patient.member_id === patientData?.id) {
              return {
                ...patient,
                [type === "tests" ? "test_id" : "package_id"]: patient[
                  type === "tests" ? "test_id" : "package_id"
                ].filter((id) => id !== cartData.id),
              };
            }
            return patient;
          }),
        };
      });
    }
  };

  useEffect(() => {
    // Extract test and package IDs from cartDetails
    const cartTestIds = new Set(cartDetailsList?.test?.map((test) => test.id));
    const cartPackageIds = new Set(cartDetailsList?.package?.map((pkg) => pkg.id));

    const updatedPatientData = [...(patientCheckedData?.data || [])];


    if (patientsList) {
      patientsList?.data?.response?.forEach((patientData) => {

        // Handle test_id: if it's not an array, convert it to an array
        const testIds = Array.isArray(patientData?.test_id) ? patientData.test_id : [patientData?.test_id];
        testIds.forEach((pat_data) => {
          if (pat_data) {
            const isTestIdInCart = cartTestIds.has(pat_data);

            if (isTestIdInCart) {
              const existingPatientIndex = updatedPatientData.findIndex(
                (existingData) => existingData?.member_id === patientData?.id
              );

              if (existingPatientIndex !== -1) {
                const testIdExists = updatedPatientData[existingPatientIndex].test_id.includes(pat_data);
                if (!testIdExists) {
                  updatedPatientData[existingPatientIndex].test_id.push(pat_data);
                }
              } else {
                updatedPatientData.push({
                  lab_id: cartDetailsListLocal?.center_id,
                  city_id: radioCityId?.selectedCityId,
                  member_id: patientData.id,
                  test_id: [pat_data],
                  package_id: [],
                });
              }
            }
          }
        });

        // Handle lab_package_id: if it's not an array, convert it to an array
        const packageIds = Array.isArray(patientData?.lab_package_id) ? patientData.lab_package_id : [patientData?.lab_package_id];
        packageIds.forEach((pat_data) => {
          if (pat_data) {
            const isPackageIdInCart = cartPackageIds.has(pat_data);

            if (isPackageIdInCart) {
              const existingPatientIndex = updatedPatientData.findIndex(
                (existingData) => existingData.member_id === patientData.id
              );

              if (existingPatientIndex !== -1) {
                const packageIdExists = updatedPatientData[existingPatientIndex].package_id.includes(pat_data);

                if (!packageIdExists) {
                  updatedPatientData[existingPatientIndex].package_id.push(pat_data);
                }
              } else {
                updatedPatientData.push({
                  member_id: patientData.id,
                  test_id: [],
                  package_id: [pat_data],
                });
              }
            }
          }
        });

      });
    }

    // Set the updated patient data
    setPatientCheckedData({ data: updatedPatientData });
  }, [patientsList]);


  useEffect(() => {
    console.log(patientCheckedData)
  }, [patientCheckedData])

  useEffect(() => {
    return () => {
      setPatientCheckedData({ data: [] });
    };
  }, []);



  return (
    <div className="patient-card-details-box">
      {(cartDetailsList?.test?.length > 0 || cartDetailsList?.package?.length > 0) && patientsList?.data?.response?.length > 0 &&
        patientsList?.data?.response?.map((patientData) => {

          const isMemberSelected = patientCheckedData?.data.some(
            (data) => data.member_id === patientData?.id
          );

          return (
            <div className="patient-detail-box">
              <div className="patient-main-detail">
                <div className="patient-detail-content">
                  <p>{patientData?.name}</p>
                  <p>
                    {patientData?.gender}, {patientData?.age},{" "}
                    {patientData?.relation}
                  </p>
                </div>
                <div className="patient-buttons-box">
                  <button onClick={() => handleEditPatient(patientData)}>
                    <img src={patientEdit} width="100%" alt="review" />
                  </button>
                  <button
                    onClick={() =>
                      handleDeletepatient({ member_id: patientData?.id })
                    }
                  >
                    <img src={patientDelete} width="100%" alt="review" />
                  </button>
                  <input
                    type="checkbox"
                    className="main-checkbox form-check-input"
                    onChange={(e) => handlePatientCheckbox(e, patientData)}
                    checked={isMemberSelected}
                  />
                </div>
              </div>
              <hr />
              {cartDetailsList?.test?.map((cartData) => {
                const isMemberSelected = patientCheckedData?.data.some(
                  (data) => data.member_id === patientData?.id
                );

                const isTestChecked =
                  isMemberSelected &&
                  patientCheckedData?.data
                    .find((data) => data.member_id === patientData?.id)
                    ?.test_id.some((t_data) => t_data === cartData.id);

                return (
                  <div className="patient-member-detail-box d-flex align-items-center justify-content-between">
                    <div className="patient-member-image">
                      <img src={appPackageIcon} width="100%" alt="review" />
                      <p>{cartData?.name}</p>
                    </div>
                    <div className="patient-check">
                      <input
                        checked={isTestChecked}
                        type="checkbox"
                        disabled={!isMemberSelected}
                        className="form-check-input small-check"
                        onChange={(e) =>
                          handleMemberCheckbox(
                            e,
                            cartData,
                            patientData,
                            "tests"
                          )
                        }
                      />
                    </div>
                  </div>
                );
              })}
              {cartDetailsList?.package?.map((cartData) => {
                // Check if the member is selected
                const isMemberSelected = patientCheckedData?.data.some(
                  (data) => data.member_id === patientData?.id
                );

                // Check if the package is associated with the selected member
                const isPackageChecked =
                  isMemberSelected &&
                  patientCheckedData?.data
                    .find((data) => data.member_id === patientData?.id)
                    ?.package_id.some((t_data) => t_data === cartData.id);

                return (
                  <div className="patient-member-detail-box d-flex align-items-center justify-content-between">
                    <div className="patient-member-image">
                      <img src={appPackageIcon} width="100%" alt="review" />
                      <p>{cartData?.name}</p>
                    </div>
                    <div className="patient-check">
                      <input
                        checked={isPackageChecked}
                        type="checkbox"
                        disabled={!isMemberSelected}
                        className="form-check-input small-check"
                        onChange={(e) =>
                          handleMemberCheckbox(
                            e,
                            cartData,
                            patientData,
                            "packages"
                          )
                        }
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}

      <Toaster
        position="top-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          // Define default options
          className: "",
          duration: 3000,
          style: {
            background: "#fff",
            color: "#363636",
          },
        }}
      />
    </div>
  );
};

export default RadioPatientCard;
