import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { dental_book, eye_book, opd_book } from '../../../Redux/actions/PostApiCall';
import LoaderAnime from "../../Medicine/animation/prescription_loader";
import Medicine_Success from '../../Medicine/animation/Medicine_Success';
import useLocationState from '../../Medicine/hooks/useLocationState';
import { headerLogo } from '../../../images';
import { genderList, relationshipList } from '../../../data';
import { validateNumberInput } from '../../../utils/AllFunction';
import { DentalBanner2, DentalBannerImage1, DentalBannerImage2, EyeBanner, EyeBannerImage1, EyeBannerImage2, opdBanner } from '../Images';
import { fetchPincode } from '../../../Redux/actions/GetApiCall';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';



const OtherPartnerForm = () => {
    const [successmodal, setSuccessModal] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const DataId = useLocationState();
    const [city, setCity] = useState("");
    const cityData = useSelector(state => state.medicine_pincode);
    const EyeData = useSelector(state => state?.eye_book_response)
    const DentalData = useSelector(state => state?.dental_book_response)
    console.log(DataId)


    const { register, watch, handleSubmit, formState: { errors } } = useForm(
    );
    const pincodeValue = watch("pincode"); // Local state for city

    const onSubmit = async (data) => {
        setModalShow(true)
        const onDataSubmit = {
            partner: "Other",
            pincode: data?.pincode,
            // city: city,
            city: data?.city,
            patient_name: data?.patient_name,
            package_id: DataId?.pack,
            patient_age: data?.patient_age,
            patient_age_type: data?.age_type,
            patient_gender: data?.gender,
            patient_relation: data?.relation,
            hospital_name: data?.hospital_name,
            doctor_name: data?.doctor_name,
        }
        console.log(onDataSubmit)
        if (DataId?.type === "Eye") {
            await dispatch(eye_book(onDataSubmit))
        }
        else {
            await dispatch(dental_book(onDataSubmit))
        }
    }


    useEffect(() => {
        if (EyeData?.status === "success" || DentalData?.status === "success") {
            setSuccessModal(false)
            setSuccessModal(true)
            setTimeout(() => {
                navigate("/home")
            }, 2000);
        }
    }, [EyeData, DentalData])

    // useEffect(() => {
    //     if (pincodeValue?.length === 6) {
    //         dispatch(fetchPincode(pincodeValue));
    //     } else if (pincodeValue === "") {
    //         setCity(""); // Clear the city state when pincode is empty
    //     }
    // }, [pincodeValue, dispatch]);

    // Update city state based on the Redux response
    // useEffect(() => {
    //     if (cityData?.data?.success) {
    //         setCity(cityData.data.city);
    //     }
    // }, [cityData]);


    return (
        <div className="home-review-banner">
            {modalShow && <LoaderAnime showModal={modalShow} />}
            <div className="home-app-header d-flex justify-content-start align-items-center">

                <div className="header-logo mx-3">
                    <img
                        src={headerLogo}
                        className="header-medibhai-logo"
                        width="100%"
                        alt="header-logo"
                    />
                    <p className='opd-text'>{DataId?.type}</p>
                </div>
            </div>
            <div className="opd-wrapper-cover-data mt-4">
                <div className="container">
                    <div className="image-form-opd">
                        {DataId?.type === "Dental" ? (
                            <div class="banner">
                                <Swiper
                                    spaceBetween={15}
                                    slidesPerView={1}
                                    loop="true"
                                    centeredSlides="true"
                                    autoplay={{ delay: 2000, disableoninteraction: false }}
                                    modules={[Autoplay]}
                                >
                                    <div className="swiper-wrapper">
                                        <SwiperSlide className="bannersliderimg">
                                            <div className="denatl-box">
                                                <img
                                                    src={DentalBannerImage1}
                                                    width="100%"
                                                    alt="app-banner-img"
                                                />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide className="bannersliderimg">
                                            <div className="denatl-box">
                                                <img
                                                    src={DentalBannerImage2}
                                                    width="100%"
                                                    alt="app-banner-img"
                                                />
                                            </div>
                                        </SwiperSlide>
                                    </div>
                                </Swiper>
                            </div>
                        ) : (
                            <Swiper
                                spaceBetween={15}
                                slidesPerView={1}
                                loop="true"
                                centeredSlides="true"
                                autoplay={{ delay: 2000, disableoninteraction: false }}
                                modules={[Autoplay]}
                            >
                                <div className="swiper-wrapper">
                                    <SwiperSlide className="bannersliderimg">
                                        <div className="denatl-box">
                                            <img
                                                src={EyeBannerImage1}
                                                width="100%"
                                                alt="app-banner-img"
                                            />
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide className="bannersliderimg">
                                        <div className="denatl-box">
                                            <img
                                                src={EyeBannerImage2}
                                                width="100%"
                                                alt="app-banner-img"
                                            />
                                        </div>
                                    </SwiperSlide>
                                </div>
                            </Swiper>
                        )}
                    </div>

                    <form onSubmit={handleSubmit(onSubmit)}>

                        <div className="form-page-data">
                            <div className="page-heading-text">
                                <p>Fill The Details</p>
                            </div>

                            <div className="form-page">
                                <input type="text" placeholder='Enter Patient Name' {...register('patient_name', { required: true })} />
                                {errors.patient_name && <span className='error-text-mssg'>This field is Required</span>}
                                <div className="option-data-page">
                                    <div className="gender-select-page">
                                        <select
                                            name="gender"
                                            {...register('gender', { required: true })}>
                                            <option value="" selected disabled>Select Gender</option>
                                            {genderList.map((data, index) => (
                                                <option key={index} value={data?.text}>{data?.text}</option>

                                            ))}
                                        </select>
                                        {errors.gender && <span className='error-text-mssg gen-text'>Gender is required</span>}
                                    </div>
                                    <div className="gender-select-page">
                                        <select
                                            name="relation"
                                            {...register('relation', { required: true })}>
                                            <option value="">Select Relation</option>
                                            {relationshipList.map((data, index) => (
                                                <option key={index} value={data?.text}>{data?.text}</option>
                                            ))}
                                        </select>
                                        {errors.relation && <span className='error-text-mssg gen-text'>Relation is required</span>}
                                    </div>
                                </div>
                                <div className="option-data-page mb-0">
                                    <div className="gender-select-page">
                                        <input type="text" placeholder='Enter Patient Age' onInput={validateNumberInput} maxLength={3} {...register('patient_age', { required: true })} />
                                        {errors.patient_age && <span className='error-text-mssg'>age is required</span>}
                                    </div>
                                    <div className="gender-select-page">
                                        <select
                                            {...register("age_type", {
                                                required: "Age type is required",
                                            })}
                                            defaultValue="" // sets default selected value
                                        >
                                            <option value="" disabled>
                                                Select Age Type
                                            </option>
                                            <option value="days">Days</option>
                                            <option value="months">Month</option>
                                            <option value="years">Years</option>
                                        </select>

                                        <span className="error-text-mssg gen-text">
                                            {errors.age_type && errors.age_type.message}
                                        </span>
                                    </div>
                                </div>
                                <input type="text" placeholder='Enter Hospital Name' {...register('hospital_name', { required: true })} />
                                {errors.hospital_name && <span className='error-text-mssg'>hospital name is required</span>}
                                <input type="text" placeholder='Enter Doctor Name' {...register('doctor_name', { required: true })} />
                                {errors.doctor_name && <span className='error-text-mssg'>doctor name is required</span>}

                                <div className='mb-3'>
                                    <div className="inputcontainer">
                                        <input
                                            placeholder="Enter Pincode"
                                            style={{ marginBottom: "0px" }}
                                            {...register("pincode", {
                                                required: "Pincode is required",
                                                pattern: {
                                                    value: /^[0-9]{6}$/,
                                                    message: "Invalid pincode"
                                                },
                                            })}
                                            // disabled={cityData?.loader === true}
                                            maxLength={6}
                                            onInput={validateNumberInput}
                                        />
                                        {/* {cityData?.loader === true && (
                                            <div className="icon-container">
                                                <i className="loader"></i>
                                            </div>
                                        )} */}
                                    </div>
                                    {/* {cityData?.loader === false && pincodeValue && pincodeValue.length === 6 && (
                                        <p className={cityData?.data?.success ? 'green' : 'red'}>
                                            {cityData?.data?.message}
                                        </p>
                                    )} */}

                                    {errors.pincode && <p className="error-message">{errors.pincode.message}</p>}
                                </div>

                                <input
                                    type="text"
                                    id="city"
                                    // value={city}
                                    placeholder='Enter City'
                                    {...register("city", {
                                        required: "city is required",
                                    })}
                                />
                                {errors.city && <p className="error-message">{errors.city.message}</p>}
                            </div>
                        </div>

                        <div className="submit-btn-wrap">
                            <button>Submit</button>
                        </div>
                    </form>
                </div>
            </div>
            {(EyeData || DentalData) && <Medicine_Success showModal={successmodal} message={DentalData?.message || EyeData?.message} status={DentalData?.success || EyeData?.success} />}
        </div>
    )
}

export default OtherPartnerForm;