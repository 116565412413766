import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import AppHeader from "../../Medicine/Components/Medicine_Header";
import AppHeader from "../Pages/Medicine/Components/Medicine_Header";
import { needHelp } from "../images";
import Accordion from "react-bootstrap/Accordion";
import { getNeedHelpData } from "../Redux/actions/GetApiCall";
import MediLoader from "../Pages/Medicine/animation/MediLoader";
import useLocationState from "../Pages/Medicine/hooks/useLocationState";

const NeedHelpHome = () => {
  const dispatch = useDispatch();
  const type = useLocationState();
  console.log(type);
  const needHelpData = useSelector((state) => state.need_help_response);
  useEffect(() => {
    dispatch(getNeedHelpData());
  }, []);

  const DataHelp = needHelpData?.data?.data;

  let filteredDataHelp;

  if (Array.isArray(type)) {
    filteredDataHelp = type;
  } else if (typeof type === "string") {
    filteredDataHelp = DataHelp?.[type] ?? [];
  } else {
    filteredDataHelp = [];
  }

  console.log(filteredDataHelp);

  return (
    <div className="home-main-cover-wrapper">
      <div className="home-app-wrapper">
        <div className="home-app-main-cover">
          <div className="home-review-banner mb-4">
            <AppHeader Title="Need Help" />
            {needHelpData?.loader ? (
              <div
                className=""
                style={{
                  minHeight: "60vh",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <MediLoader />
              </div>
            ) : (
              <div className="need-help-main-cover-wrapper">
                <div className="need-help-banner">
                  <img src={needHelp} alt="need help"></img>
                </div>
                <div className="need-help-accordion-main-cover-wrapper">
                  <Accordion defaultActiveKey="0">
                    {filteredDataHelp?.map((helpData, ind) => {
                      return (
                        <Accordion.Item eventKey={ind}>
                          <Accordion.Header>
                            <p dangerouslySetInnerHTML={{ __html: helpData?.question }} style={{marginBottom:"0px"}} className="repo-answer"></p>
                          </Accordion.Header>
                          <Accordion.Body><p dangerouslySetInnerHTML={{ __html: helpData?.answer }} style={{marginBottom:"0px"}} className="repo-answer"></p></Accordion.Body>
                        </Accordion.Item>
                      );
                    })}
                  </Accordion>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NeedHelpHome;
