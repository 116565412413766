import React from 'react'
import { headerLogo } from '../../../images'
import '../Medicine_Style/eye.css'
import { DentalBanner, DentalBanner2, DentalBanner3, DentalBanner4, DentalBanner5, DentalBannerImage1, DentalBannerImage2, EyeBanner, EyeImage, EyeImage2, EyeImage3 } from '../Images'
import { useNavigate } from 'react-router'
import useLocationState from '../hooks/useLocationState'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay } from 'swiper/modules'

const Dental = () => {
    const naviagte = useNavigate();
    const PackData = useLocationState()
    const handleEyeForm = (item) => {
        const data = {
            item: item,
            type: "Dental",
            pack: PackData?.package_id
        }
        naviagte("/eye-form", { state: data })
    }
    const partners = [
        { name: "Sabka dentist", image: DentalBanner },
        { name: "Secure Dental Care", image: DentalBanner4 },
        { name: "Oroglee", image: DentalBanner5 },
        { name: "Clove Dental", image: DentalBanner3 }
    ];

    const handleOtherPartner = () => {
        const data = {
            type: "Dental",
            pack: PackData?.package_id
        }
        naviagte("/other-partner-form", { state: data })
    }
    return (
        <div className='home-review-banner'>
            <div className="home-app-header sticky-top">
                <div className="header-logo">
                    <img src={headerLogo} width="100%" alt="header-logo" />
                    <div className="user-name-text-repo">
                        <p style={{ fontSize: '16px', marginLeft: '10px' }}>Dental</p>
                    </div>
                </div>
            </div>

            <div class="banner">
                <Swiper
                    spaceBetween={15}
                    slidesPerView={1}
                    loop="true"
                    centeredSlides="true"
                    autoplay={{ delay: 2000, disableoninteraction: false }}
                    modules={[Autoplay]}
                >
                    <div className="swiper-wrapper">
                        <SwiperSlide className="bannersliderimg">
                            <div className="denatl-box">
                                <img
                                    src={DentalBannerImage1}
                                    width="100%"
                                    alt="app-banner-img"
                                />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className="bannersliderimg">
                            <div className="denatl-box">
                                <img
                                    src={DentalBannerImage2}
                                    width="100%"
                                    alt="app-banner-img"
                                />
                            </div>
                        </SwiperSlide>
                    </div>
                </Swiper>
            </div>

            <div class="partners-section">
                <div className="part-repo-text-dot">
                    <div className="dot-line"></div>
                    <h3>Our Partners</h3>
                    <div className="dot-line"></div>
                </div>
                <div class="partners-logos">
                    {partners.map((item) => (
                        <div class="partner-card-dental" onClick={() => handleEyeForm(item)}>
                            <img src={item?.image} alt={item.name} />
                            <p>{item?.name}</p>
                        </div>
                    ))}
                </div>

                <div className="other-partner-repo-clover">
                    <p>For other partner please click below </p>
                    <button onClick={handleOtherPartner}>Other Partner</button>
                </div>
            </div>


        </div>
    )
}

export default Dental