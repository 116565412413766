import React from 'react'
import './verticaldoctorcard.css'
import { appOPDIcon } from '../../images'
import { useNavigate } from 'react-router';

const VerticalDoctorCard = ({ Data }) => {
    const navigate = useNavigate();
    console.log(Data)
    const handleDetails = () => {
        const DataRepo={
            doctor_id:Data?.doctor_id,
            departments:Data?.departments?.[0]?.department_id,
            hospitalID:Data?.hospitalDetails?.[0]?.hospital_id
        }
        navigate("/doctor-detail", { state: DataRepo })
    }
    return (
        <div>
            <div class="doc-card" onClick={handleDetails}>
                <div class="doc-profile-image">
                    <img src={appOPDIcon} alt="Doctor Image" class="doc-profile-image" />
                </div>

                <div class="doc-doctor-name">{Data?.doctor_name}</div>
                <div class="doc-specialization">{Data?.departments?.[0]?.department_name}</div>

                <div class="doc-qualification">{Data?.education}</div>
                <div class="doc-location">Motherhi Department Of Paediatrics & Neonatology, Bandra (W)</div>

                <div class="doc-appointment-options">
                    {Data?.online_price !== null && (
                        <div class="doc-option">
                            Online
                            <div class="doc-price">₹{Data?.online_price}</div>
                        </div>
                    )}
                    {Data?.in_person_price !== null && (
                        <div class="doc-option">
                            In-person
                            <div class="doc-price">₹{Data?.in_person_price}</div>
                        </div>
                    )}
                </div>

                <button class="doc-book-button">Book Your Appointment</button>
            </div>

        </div>
    )
}

export default VerticalDoctorCard