import React, { useContext, useEffect, useState } from "react";
import "swiper/swiper-bundle.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import { MdArrowRightAlt } from "react-icons/md";
import {
  appBlogImage,
  appCartIcon,
  appNotification,
  appPlayButton,
  headerLogo,
} from "../images";
import "swiper/css";
import { Link, NavLink, useNavigate } from "react-router-dom";
import NoDataFoundComp from "./NoDataFound";
import { WellnessCorner, WellnessSixModules } from "../data";
import { HOME_DASHBOARD_SCREEN_API, HOME_INDEX_API, MY_ACCOUNT_API, HEALTH_FEED_BLOG_API, MEDICINE_CART_MANAGER_LIST, IMAGE_HEALTH_URL } from "../Constants";
import { useDispatch, useSelector } from "react-redux";
import { fetchHomeApi } from "../Redux/actions/GetApiCall";
import { no_preview } from "../Pages/Medicine/Images";
import { useFetchHome } from "../QueryClient/MedicineQuery";
import MediLoader from "../Pages/Medicine/animation/MediLoader";

const Home = () => {

  const dispatch = useDispatch();
  // const HomeData = useSelector(state => state.home_list);
  const navigate = useNavigate();

  const {
    data: HomeData,
    error: medicineQueryError,
    isLoading: isLoadingHome,
  } = useFetchHome();

  console.log(HomeData)

  const handleVideoAll = () => {
    const VideoData = {
      videoData: HomeData?.videos
    }

    navigate("/videobox", { state: VideoData })
  }

  const handleSingleVideo = (item) => {
    navigate('/videodetailbox', { state: item })
  }

  const handleBlogDetails = (item) => {
    navigate('/blogdetail', { state: item })
  }

  const handleWellnessDetails = (item) => {
    navigate(`/${item
      .toLowerCase()
      .replace(/\s/g, "")}`, { state: HomeData?.profile_details })
  }

  const handlePackageDetails = (id) => {
    navigate('/packagedetailbox', { state: id })
  }

  const AllPackages = (Data) => {
    // console.log(Data)
    navigate('/all-packages', { state: Data })
  }

  return (
    <>
      <div className="home-review-banner">
        <div className="home-app-header sticky-top">
          <div className="header-logo">
            <img src={headerLogo} width="100%" alt="header-logo" />
            <div className="user-name-text-repo">
              {HomeData?.profile_details?.name && (
                <p> Welcome {HomeData?.profile_details?.name}</p>
              )}
            </div>
          </div>
          <div className="icons-box">
            <div className="notification-icon">
              <NavLink to="/notification">
                <img src={appNotification} width="100%" alt="notification" />
              </NavLink>
            </div>
          </div>
        </div>

        <div className="home-app-slider">
          <Swiper
            spaceBetween={0}
            slidesPerView={4}
            loop="true"
            autoplay={{ delay: 1000, disableoninteraction: false }}
            speed={2000}
            effect="slide"
            grabCursor={true}
            centeredSlides={true}
            slidesOffsetBefore={0}
            slidesOffsetAfter={0}
          modules={[Autoplay]}
          >
            <div className="swiper-wrapper">
              {WellnessSixModules.map((modulesData) =>
                modulesData?.show_hide === 0 ? null : (
                  <SwiperSlide
                    className="applabtest-repo"
                    key={modulesData.name}
                  >
                    <NavLink
                      to={`/${modulesData.name === "Teleconsultation"
                        ? "home-teleconsultation"
                        : modulesData.name === "Buy\nMedicines"
                          ? "buyMedicines"
                          : modulesData.name === "Diagnostic\nTest"
                            ? "diagnostictest"
                            : modulesData.name === "Radiology\nScreening"
                              ? "radiologyscreening"
                              : modulesData.name === "Hospital\nConsultation"
                                ? "hospital-consultation"
                                : modulesData.name === "OPD\nConsultation"
                                  ? "doctor-consultation"
                                  : modulesData.name.toLowerCase().replace(/\s/g, "")
                        }`}
                    >
                      <button className="btn-slider-repo">
                        <div className="slider-img">
                          <img
                            src={modulesData.image}
                            width="100%"
                            alt="labtest"
                          />
                        </div>
                        <p>{modulesData.name === "home-teleconsultation" ? modulesData.name = "Teleconsultation" : modulesData.name}</p>
                      </button>
                    </NavLink>
                  </SwiperSlide>
                )
              )}
            </div>
          </Swiper>
        </div>

        <div className="home-package-slider">
          {HomeData && HomeData?.active_package.length > 0 && (
            <div className="header-package-text-clover">
              <h6>My Packages</h6>
              <p onClick={() => AllPackages(HomeData?.active_package)}>View All</p>
            </div>
          )}
          <Swiper spaceBetween={10} slidesPerView={1} autoplay={{ delay: 2000, disableoninteraction: false }}
            modules={[Autoplay]}
            loop="true">
            {isLoadingHome === true ? (
              <MediLoader />
            ) :
              HomeData && HomeData?.active_package.length > 0 ? (
                HomeData?.active_package.map((packageBox, inde) => (
                  <>
                    <SwiperSlide key={packageBox.id} className="package-repo-data">
                      <div
                        onClick={() => handlePackageDetails(packageBox?.id)}
                        className="home-section-banner"

                      >
                        <img src={packageBox?.banner} alt="" />
                        {packageBox?.package_text_visibility === 0 && (
                          <div className="container">
                            <div className="home-section-main-banner">
                              <div className="row">
                                <div className="col-lg-8 col-8">
                                  <div className="banner-left-side">
                                    <div className="left-image-box">
                                      <p dangerouslySetInnerHTML={{ __html: packageBox?.description }}></p>
                                    </div>
                                    <div className="konw-more-btn">
                                      <a href="#">
                                        <p>
                                          Know more <MdArrowRightAlt />
                                        </p>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-4 col-4 pe-0">
                                  <div className="banner-right-side">
                                    <div className="package-title">
                                      <p>{packageBox?.name}</p>
                                    </div>
                                    <div className="package-date">
                                      <p>
                                        package Valid Till{" "}
                                        <span>{packageBox?.expiring_on}</span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </SwiperSlide>
                  </>
                ))
              ) : (
                <>

                </>)}
          </Swiper>
        </div>

        <div className="wellness-corner-wrapper">
          <div className="wellness-heading">
            <h5>Wellness Wings</h5>
          </div>
          <Swiper
            className="wellness-cornor-slider-repo"
            spaceBetween={-10}
            slidesPerView={3.5}
            loop="true"
            autoplay={{ delay: 2000, disableoninteraction: false }}
            modules={[Autoplay]}
          >
            <div className="swiper-wrapper">
              {WellnessCorner.map((wellnessData) => (
                <SwiperSlide className="swiper-slider-repo">
                  <div onClick={() => handleWellnessDetails(wellnessData?.name)}>
                    <div className="wellness-box">
                      <div className="corner-heading">
                        <p>{wellnessData.name}</p>
                      </div>
                      <div className="corner-image">
                        <img
                          src={wellnessData.image}
                          width="100%"
                          alt="lifestyle"
                        />
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </div>
          </Swiper>
        </div>

        {
          isLoadingHome === true ? (
            <p>
              <MediLoader />
            </p>
          ) : HomeData?.home_slider.length > 0 ? (
            <div className="dental-wrapper">
              <Swiper
                spaceBetween={15}
                slidesPerView={1}
                loop="true"
                centeredSlides="true"
              >
                <div className="swiper-wrapper">
                  {HomeData?.home_slider?.map((bannerslide) => (
                    <SwiperSlide key={bannerslide.id} className="bannersliderimg">
                      <div className="denatl-box">
                        <img
                          src={bannerslide.slider_image}
                          width="100%"
                          alt="app-banner-img"
                        />
                      </div>
                    </SwiperSlide>
                  ))}
                </div>
              </Swiper>
            </div>
          ) : (
            <NoDataFoundComp />
          )}


        <div className="videos-wrapper">
          <div className="video-box">
            <div className="health-heading">
              <h5>Videos</h5>
            </div>
            <div className="view-all" onClick={handleVideoAll}>
              <p>View All</p>
            </div>
          </div>
          {isLoadingHome === true ? (
            <p>
              <MediLoader />
            </p>
          ) : HomeData?.videos && HomeData?.videos.length > 0 ? (
            <div className="video-box-cover">
              <Swiper spaceBetween={10} slidesPerView={1.8}>
                <div className="swiper-wrapper">
                  {HomeData?.videos &&
                    HomeData?.videos.map((videoData, ind) => (
                      <SwiperSlide className="video-slider-repo">
                        <div className="video-content-box" onClick={() => handleSingleVideo(videoData)}>
                          <div className="video-time">
                            <p>{videoData.video_time}</p>
                          </div>
                          <div
                            className="video-image"
                            style={{
                              backgroundImage: `url(${videoData.icon})`,
                            }}
                          >
                            <div className="video-play">
                              <img
                                src={appPlayButton}
                                width="100%"
                                alt="play-button"
                              />
                            </div>
                          </div>
                          <div className="video-text">
                            <p>{videoData?.title}</p>
                          </div>
                        </div>
                      </SwiperSlide>
                    ))}
                </div>
              </Swiper>
            </div>
          ) : (
            <NoDataFoundComp />
          )}
        </div>


        <div className="blog-wrapper">
          <h3>Health Corner</h3>
          <div className="blog-main-cover">
            <Swiper spaceBetween={10} slidesPerView={1.1} loop="true">
              <div className="swiper-wrapper">
                {isLoadingHome === true ? (
                  <p>
                    <MediLoader />
                  </p>
                ) : HomeData && HomeData?.health_feed.length > 0 ? (
                  <div className="blog-wrapper">
                    <h3>Health Corner</h3>
                    <div className="blog-main-cover">
                      <Swiper spaceBetween={10} slidesPerView={1.1} loop="true">
                        <div className="swiper-wrapper">
                          {HomeData?.health_feed &&
                            HomeData?.health_feed.map((blogData, index) => (
                              <SwiperSlide className="blogslide-repo">
                                <div className="blog-content-box" onClick={() => handleBlogDetails(blogData)}>
                                  <div className="blog-image-box">
                                    <img src={blogData?.feature_image ? `${IMAGE_HEALTH_URL}${blogData?.feature_image
                                      }` : no_preview}
                                      onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = no_preview;
                                      }}
                                      width="100%"
                                      alt="product-icon" />
                                  </div>
                                  <div className="blog-heading-box blog-subject-text">
                                    <p>{blogData.subject}</p>
                                  </div>
                                  <div className="blog-text-box short-desc-repo">
                                    <p>{blogData.short_description}</p>
                                  </div>
                                  <div className="blog-post">
                                    <p>
                                      Posted on{" "}
                                      {
                                        new Date(blogData.created_on)
                                          .toISOString()
                                          .split("T")[0]
                                      }
                                    </p>
                                  </div>
                                </div>

                              </SwiperSlide>
                            ))}
                        </div>
                      </Swiper>
                    </div>
                  </div>
                ) : (
                  <NoDataFoundComp />
                )}
              </div>
            </Swiper>
          </div>
        </div>

      </div >
    </>
  );
};

export default Home;
