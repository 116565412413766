import React, { useEffect, useState } from 'react'
import AppHeader from "../Medicine/Components/Medicine_Header";
import { IoIosSearch } from 'react-icons/io';
import NoDataFoundComp from '../../components/NoDataFound';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCenterRadio } from '../../Redux/actions/GetApiCall';
import MediLoader from '../Medicine/animation/MediLoader';
import { IoLocationSharp } from "react-icons/io5";
import { headerLogo } from '../../images';
import { FiSearch } from 'react-icons/fi';
import { useNavigate } from 'react-router';
import { FaAngleDown, FaAngleUp } from "react-icons/fa6";


const RadioCenters = () => {
    const CenterData = useSelector(state => state.radio_centers);
    const CenterLoader = useSelector(state => state.radio_centers.loader);
    const [searchTerm, setSearchTerm] = useState('');
    const [showAllTests, setShowAllTests] = useState(false); // For "View More Tests" toggle
    const [showAllPackages, setShowAllPackages] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const radiologyCityData = JSON.parse(localStorage.getItem('selectedRadiologyData'));

    useEffect(() => {
        if (radiologyCityData) {
            dispatch(fetchCenterRadio(radiologyCityData?.selectedCityId));
        }
    }, []);

    const filteredDoctorsBySearch = CenterData?.data?.data?.filter(doctor => {
        const name = doctor?.center_name?.toLowerCase() || "";
        const city = doctor?.center_city?.toLowerCase() || "";
        const location = doctor?.center_location?.toLowerCase() || "";

        const searchTermLower = searchTerm.toLowerCase();
        return (
            name.includes(searchTermLower) ||
            city.includes(searchTermLower) ||
            location.includes(searchTermLower)
        );
    });

    const height = "75vh";

    const handleRadioCenter = (center) => {
        localStorage.setItem('selectedCenter', JSON.stringify(center));
        navigate('/radio-patients');
    };

    return (
        <div className='home-review-banner'>
            {/* Header */}
            <div className="home-app-header sticky-top">
                <div className="header-logo">
                    <img src={headerLogo} width="100%" alt="header-logo" />
                    <div className="user-name-text-repo">
                        <p style={{ fontSize: '16px', marginLeft: '10px' }}>Centers</p>
                    </div>
                </div>
            </div>

            {/* Search bar */}
            <div className="search-bar">
                <input
                    type="text"
                    placeholder="Search centers..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    onKeyPress={(e) => {
                        const regex = /^[a-zA-Z\s]*$/; // Allow only letters and spaces
                        if (!regex.test(e.key)) {
                            e.preventDefault(); // Prevent invalid input
                        }
                    }}
                />
                <FiSearch />
            </div>

            <div className="lab-book-categories">
                <div className="radio-cat-heading mt-4">
                    <h5>Total Centers : {CenterData?.data?.data?.length}</h5>
                </div>
                {CenterLoader ? (
                    <MediLoader height={height} />
                ) : CenterData && CenterData?.data?.data?.length > 0 ? (
                    filteredDoctorsBySearch?.length > 0 ? ( // Check if the search has results
                        <div className="total-center-box">
                            {filteredDoctorsBySearch.map((center) => (
                                <div className="total-center-box-cover" key={center.id}>
                                    <div className="center-content-box">
                                        <div className="radio-repo-dev">
                                            <h6>{center.center_name}</h6>
                                        </div>
                                        <p>
                                            <IoLocationSharp /> <span>{center?.center_location}, {center?.center_city}-{center?.center_pincode}, {center?.center_state}</span>
                                        </p>
                                        {center?.radiologyData?.test?.length > 0 && (
                                            <div className="available-tests-clover-repo">
                                                <p>Available Tests:</p>
                                                <ul>
                                                    {center.radiologyData
                                                        ?.test?.slice(0, showAllTests ? center.radiologyData.length : 2) // Show either all or only 2 tests
                                                        .map((testItem, index) => (
                                                            <li key={index}>
                                                                <div className="test-dev-dev">
                                                                    <p>{testItem.name}</p>
                                                                </div>
                                                                <div className="price-dev-dev">
                                                                    <p>MB Price: ₹ {testItem?.medibhai_price}</p>
                                                                    <span>
                                                                        {testItem?.mrp > 0 && testItem?.medibhai_price !== testItem?.mrp && (
                                                                            <>
                                                                                MRP: ₹ {testItem?.mrp}
                                                                            </>
                                                                        )}
                                                                    </span>
                                                                </div>
                                                            </li>
                                                        ))}
                                                </ul>
                                                {center.radiologyData?.test?.length > 2 && (
                                                    <div className="show-repo-btn-dev-dev">
                                                        <button onClick={() => setShowAllTests(!showAllTests)}>
                                                            {showAllTests ? (
                                                                <>
                                                                    <FaAngleDown /> View Less Tests
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <FaAngleUp /> View More Tests
                                                                </>
                                                            )}
                                                        </button>

                                                    </div>
                                                )}
                                            </div>
                                        )}

                                        {center?.radiologyData?.package?.length > 0 && (
                                            <div className="available-tests-clover-repo mt-2">
                                                <p>Available Packages:</p>
                                                <ul>
                                                    {center.radiologyData
                                                        ?.package?.slice(0, showAllPackages ? center.radiologyData.length : 2) // Show either all or only 2 packages
                                                        .map((packageItem, index) => (
                                                            <li key={index}>
                                                                <div className="test-dev-dev">
                                                                    <p>{packageItem.name}</p>
                                                                </div>
                                                                <div className="price-dev-dev">
                                                                    <p>MB Price: ₹ {packageItem?.medibhai_price}</p>
                                                                    <span>
                                                                        {packageItem?.mrp > 0 && packageItem?.medibhai_price !== packageItem?.mrp && (
                                                                            <>
                                                                                MRP: ₹ {packageItem?.mrp}
                                                                            </>
                                                                        )}
                                                                    </span>
                                                                </div>
                                                            </li>
                                                        ))}
                                                </ul>
                                                {center.radiologyData?.package?.length > 2 && (
                                                    <div className="show-repo-btn-dev-dev">
                                                        <button onClick={() => setShowAllPackages(!showAllPackages)}>
                                                            {showAllPackages ? (
                                                                <>
                                                                    <FaAngleDown /> View Less Packages
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <FaAngleUp /> View More Packages
                                                                </>
                                                            )}
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                        <div className="center-book-button">
                                            <button onClick={() => handleRadioCenter(center)}>
                                                <p>Select Center</p>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div className="no-results">
                            <p>No centers found</p>
                        </div>
                    )
                ) : (
                    <NoDataFoundComp />
                )}

            </div>
        </div>
    )
}

export default RadioCenters;
