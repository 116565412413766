import { useState, useEffect, useRef } from 'react';

const useFetchData = (fetchFunction, dependencies = [], initialPage = 1) => {
    const [data, setData] = useState([]);
    const [pageNo, setPageNo] = useState(initialPage);
    const [hasMore, setHasMore] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const scrollContainerRef = useRef(null);

    const fetchData = async (page) => {
        setIsLoading(true);
        try {
            const response = await fetchFunction(page);
            if (response?.data?.length === 0 || page >= response?.total_pages) {
                setHasMore(false);
            } else {
                setData((prevData) => [...prevData, ...(response?.data || [])]);
                setHasMore(true);
            }
        } catch (error) {
            console.error('Fetch data error:', error);
            setHasMore(false);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (pageNo > 1) {
            fetchData(pageNo);
        }
    }, [pageNo, ...dependencies]);

    const handleScroll = () => {
        if (scrollContainerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = scrollContainerRef.current;

            if (scrollTop + clientHeight >= scrollHeight - 10 && hasMore && !isLoading) {
                setPageNo((prevPageNo) => prevPageNo + 1);
            }
        }
    };

    useEffect(() => {
        const currentRef = scrollContainerRef.current;
        if (currentRef) {
            currentRef.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (currentRef) {
                currentRef.removeEventListener('scroll', handleScroll);
            }
        };
    }, [hasMore, isLoading]);

    return { data, setData, pageNo, setPageNo, hasMore, isLoading, scrollContainerRef, fetchData };
};

export default useFetchData;
