import React, { useEffect, useState } from 'react'
import { appOPDIcon, headerLogo } from '../../images'
import useLocationState from '../Medicine/hooks/useLocationState'
import { MdSchool } from 'react-icons/md'
import { FaBuilding, FaHospital } from 'react-icons/fa'
import './doctororder.css'
import { useDispatch, useSelector } from 'react-redux'
import { fetchDoctorOrderDetail, fetchPackageList } from '../../Redux/actions/GetApiCall'
import { medi_wallet_image, savings_image } from '../Medicine/Images'
import LoaderAnime from '../Medicine/animation/prescription_loader'
import { doctorAmountUtilization, doctorCapturePayment } from '../../Redux/actions/PostApiCall'
import Medicine_Success from '../Medicine/animation/Medicine_Success'
import { useNavigate } from 'react-router'
import toast from 'react-hot-toast'
import useRazorpay from 'react-razorpay'
import { IoIosVideocam } from 'react-icons/io'

const DoctorOrder = () => {
    const Data = useLocationState();
    console.log(Data)
    const [successmodal, setSuccessModal] = useState(false);
    const packagesData = useSelector(state => state.package_list);
    const DoctorOrderData = useSelector(state => state.doctor_order_detail);
    const DoctorAmountUtilization = useSelector(state => state.doctor_amount_utilization);
    console.log(DoctorOrderData)
    const DoctorCapturePayment = useSelector(state => state.doctor_capture_amount);
    const [selectedData, setSelectedData] = useState('');
    const [modalShow, setModalShow] = useState(false);
    const [walletId, setWalletId] = useState("");
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [Razorpay] = useRazorpay();
    const [isChecked, setIsChecked] = useState(false);

    useEffect(() => {
        dispatch(fetchPackageList());
        if (Data) {
            dispatch(fetchDoctorOrderDetail(Data?.id))
        }
    }, [Data])

    const handleWalletChange = (e) => {
        const isCheckedNow = e.target.checked;
        setIsChecked(isCheckedNow);

        if (isCheckedNow && selectedData) {
            dispatch(fetchDoctorOrderDetail(Data?.id, selectedData?.id, selectedData.wallet_id)); // Call API with selected package ID and wallet ID
            setWalletId(selectedData.wallet_id);
        } else {
            dispatch(fetchDoctorOrderDetail(Data?.id, selectedData?.id)); // Call API without wallet ID
            setWalletId('');
        }
    };

    const handleSelectPackage = (e) => {
        const selectedValue = e.target.value;
        if (selectedValue) {
            try {
                const selectedItem = JSON.parse(selectedValue);
                setSelectedData(selectedItem);
                dispatch(fetchDoctorOrderDetail(Data?.id, selectedItem?.id));
            } catch (error) {
                console.error('Invalid JSON input:', selectedValue);
            }
        } else {
            setSelectedData('');
        }
    };

    useEffect(() => {
        if (DoctorOrderData?.status === "loading") {
            setModalShow(true)
        } else {
            setModalShow(false)
        }
    }, [DoctorOrderData])


    const handleConsultOrder = async () => {
        setModalShow(true)
        const data = {
            booked_id: Data?.id,
            package_id: selectedData ? selectedData?.id : "",
            wallet_id: walletId ? walletId : ""
        }
        await dispatch(doctorAmountUtilization(data))
    }

    useEffect(() => {
        if (DoctorAmountUtilization?.status === "success" && DoctorAmountUtilization?.data?.is_payable === 0) {
            setModalShow(false);
            const data = {
                orderId: Data?.id,
                tabKey: "fifth",
            };
            setTimeout(() => {
                navigate("/thank-you", { state: data });
            }, 100);
        }
    }, [DoctorAmountUtilization])


    useEffect(() => {
        if (DoctorAmountUtilization?.loader) {
            setModalShow(true);
        } else if (DoctorAmountUtilization?.status === "failure") {
            setModalShow(false);
            toast.error(DoctorAmountUtilization?.error);
        }
        else if (
            DoctorAmountUtilization?.data?.razorpay_details &&
            DoctorAmountUtilization?.status === "success" &&
            DoctorAmountUtilization?.data?.is_payable === 1
        ) {
            const options = {
                ...DoctorAmountUtilization.data.razorpay_details,
                order_id:
                    DoctorAmountUtilization.data.razorpay_details?.razorpay_order_id,
                handler: function async(response) {
                    dispatch(
                        doctorCapturePayment({
                            transaction_id: response.razorpay_payment_id,
                            booked_id: Data?.id,
                            package_id: selectedData ? selectedData?.id : "",
                            wallet_id: walletId ? walletId : ""
                        })
                    );
                },
                modal: {
                    ondismiss: function () {
                        setModalShow(false);
                        toast.error("Payment was cancelled.");
                    },
                },
            };
            const rzp1 = new Razorpay(options);
            rzp1.open();
        }
    }, [DoctorAmountUtilization]);

    useEffect(() => {
        if (DoctorCapturePayment?.status === "success") {
            setModalShow(false);
            const data = {
                orderId: Data?.id,
                tabKey: "fifth",
            };
            setTimeout(() => {
                navigate("/thank-you", { state: data });
            }, 100);
        }
    }, [DoctorCapturePayment]);

    const repoprice = DoctorOrderData?.data?.payment_summary
        .filter(
            (lab_cal_Data) =>
                lab_cal_Data.key === "Total Payable"
        )?.[0]?.value




    return (
        <div className='home-review-banner'>
            {modalShow && <LoaderAnime showModal={modalShow} />}
            <div className="home-app-header sticky-top">
                <div className="header-logo">
                    <img src={headerLogo} width="100%" alt="header-logo" />
                    <div className="user-name-text-repo">
                        <p style={{ fontSize: '16px', marginLeft: '10px' }}>Book Consultation</p>
                    </div>
                </div>
            </div>

            <div className="doctor-detail-repo-corner">
                <div className="doctor-data-repo">
                    <div className="doctor-tepo-image">
                        <img src={appOPDIcon} alt="" />
                        <div className="dot"></div>
                    </div>
                    <div className="doctor-data-box-tepo">
                        <h5 style={{ marginBottom: '0px' }}>{Data?.doctorData?.doctor_name}</h5>
                        <p style={{ fontSize: "11px", color: "#757575", marginBottom: '5px' }}>{Data?.doctorData?.departments?.[0]?.department_name}</p>
                        <p className="doctor-category"><MdSchool /> {Data?.doctorData?.education}</p>
                        <p className="doctor-category"><FaBuilding /> {DoctorOrderData?.data?.data?.hospital?.address}</p>
                    </div>
                </div>
            </div>
            <div className="consultation-card mt-3">
                {Data?.panelType === 1 ? (
                    <div className="symptoms-preview-card-box repo-card-order-clover d-flex align-items-center">
                        <div className="symptoms-preview-icon-box repol-clover-repo">
                            <IoIosVideocam />
                        </div>
                        <h6>
                            Online Consultation
                        </h6>
                    </div>
                ) : (
                    <div className="symptoms-preview-card-box repo-card-order-clover d-flex align-items-center">
                        <div className="symptoms-preview-icon-box repol-clover-repo">
                            <FaHospital />
                        </div>
                        <h6>
                            In-person Consultation
                        </h6>
                    </div>
                )}
                <h6 className="consultation-card-title">Patient Details</h6>
                <p className="consultation-patient-name">
                    Patient Name :{" "}
                    <span>
                        {DoctorOrderData?.data?.data?.patient_name}
                    </span>
                </p>
                <div className="repo-clover-details-clover-card">
                    <div className="preview-card-detail">
                        <p>
                            Age : <span>
                            {DoctorOrderData?.data?.data?.age} {DoctorOrderData?.data?.data?.age_type?.charAt(0).toUpperCase() + DoctorOrderData?.data?.data?.age_type?.slice(1).toLowerCase()}
                            </span>
                        </p>
                    </div>
                    <div className="preview-card-detail">
                        <p>
                            Gender :
                            <span>
                            {DoctorOrderData?.data?.data?.gender}
                            </span>
                        </p>
                    </div>
                    <div className="preview-card-detail">
                        <p>
                            Relation :
                            <span>
                            {DoctorOrderData?.data?.data?.relation}
                            </span>
                        </p>
                    </div>
                </div>
            </div>

            {packagesData?.data?.active_package?.length > 0 && (
                <div className="packages-cover-wrapper mt-4">
                    <div className="options-wrapper">
                        <select value={selectedData ? JSON.stringify(selectedData) : ''} onChange={handleSelectPackage}>
                            <option value="">Select Package</option> {/* Default selected and disabled */}
                            {packagesData?.data?.active_package?.map((item) => (
                                <option key={item.id} value={JSON.stringify(item)}>
                                    {item.name}
                                </option>
                            ))}
                        </select>

                    </div>
                </div>
            )}

            {selectedData !== "" && (
                <>
                    <div className="wallet-repo-wrapper">
                        <div className="check-data-wrapper">
                            <div className="checkbox-wrapper">
                                <input
                                    id="terms-checkbox-37"
                                    name="checkbox"
                                    type="checkbox"
                                    checked={isChecked} // Bind to the checkbox state
                                    onChange={handleWalletChange} // Handle change event directly
                                />
                                <label className="terms-label" htmlFor="terms-checkbox-37">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 200 200" className="checkbox-svg">
                                        <mask fill="white" id="path-1-inside-1_476_5-37">
                                            <rect height="200" width="200"></rect>
                                        </mask>
                                        <rect mask="url(#path-1-inside-1_476_5-37)" strokeWidth="40" className="checkbox-box" height="200" width="200"></rect>
                                        <path strokeWidth="15" d="M52 111.018L76.9867 136L149 64" className="checkbox-tick"></path>
                                    </svg>
                                </label>
                            </div>
                            <div className="wrapper-text-content-box">
                                <p>Medibhai Wallet</p>
                                <span>Remaining Balance : ₹ {selectedData?.wallet_details?.opd?.remaining}</span>
                            </div>
                        </div>
                        <div className="used-data-wrapper">
                            <img src={medi_wallet_image} alt="" />
                        </div>

                    </div>
                    {isChecked && repoprice?.length > 0 && modalShow === false && (
                        <div className="wallet-heading-insu">
                            <p>
                                Your wallet balance is insufficient ₹{repoprice}  payment is required online
                            </p>
                        </div>
                    )}
                </>
            )}

            <div className="payment-repo-cover-box mx-2" style={{ marginBottom: "134px" }}>
                <div className="payment-text-repo">
                    <p>Payment Summary</p>
                </div>
                <div className="payment-summary-box-repo">
                    {DoctorOrderData?.data?.payment_summary &&
                        DoctorOrderData?.data?.payment_summary
                            .filter(
                                (lab_cal_Data) =>
                                    lab_cal_Data.key !== "Total Payable Price" &&
                                    lab_cal_Data.key !== "Total Savings"
                            )
                            .map((filteredData) => (
                                <div
                                    className="price-payment-sum-repo"
                                    key={filteredData.key}
                                >
                                    <p>{filteredData.key}</p>
                                    <p>
                                        {new Intl.NumberFormat("en-IN", {
                                            style: "currency",
                                            currency: "INR",
                                            minimumFractionDigits: 0,
                                        }).format(filteredData.value)}
                                    </p>
                                </div>
                            ))}
                </div>
                <div className="fin-text-repo">
                    {DoctorOrderData?.data?.payment_summary &&
                        DoctorOrderData?.data?.payment_summary
                            .filter(
                                (lab_cal_Data) =>
                                    lab_cal_Data.key === "Total Payable Price" ||
                                    lab_cal_Data.key === "Total Savings"
                            )
                            .map((filteredData) => (
                                <div
                                    className={`price-payment-sum-repo ${filteredData.key === "Total Savings"
                                        ? "text-success price-payment-sum-repo saving-data-p"
                                        : ""
                                        }`}
                                    key={filteredData.key}
                                >
                                    <p>
                                        <img
                                            src={savings_image}
                                            alt=""
                                            style={{ marginRight: "5px" }}
                                        />
                                        {filteredData.key}
                                    </p>
                                    <p>
                                        {new Intl.NumberFormat("en-IN", {
                                            style: "currency",
                                            currency: "INR",
                                            minimumFractionDigits: 0,
                                        }).format(filteredData.value)}
                                    </p>
                                </div>
                            ))}
                </div>
            </div>

            <div className="footer-repo-head-boxs sticky-lg-bottom sticky-md-bottom sticky-bottom pb-2">
                <div className="product-price-repo-store">
                    {DoctorOrderData?.data?.data?.order_details
                        ?.net_amount <= 0.0 ? (
                        <div class="free-card">
                            <h5>Free</h5>
                        </div>
                    ) : (
                        <p>
                            Total{" "}
                            <span>
                                ₹{" "}
                                {
                                    DoctorOrderData?.data?.data
                                        ?.order_details?.net_amount
                                }
                            </span>
                        </p>
                    )}
                </div>
                <div className="product-btn-box-store">
                    <button
                        className="repo-cont-btn"
                        onClick={handleConsultOrder}
                    >
                        Book Now
                    </button>
                </div>
            </div>
            {DoctorAmountUtilization && <Medicine_Success showModal={successmodal} message={DoctorAmountUtilization?.message} status={DoctorAmountUtilization?.status === "failure" ? false : DoctorAmountUtilization?.success} />}
        </div>
    )
}

export default DoctorOrder