import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDoctorList } from '../../Redux/actions/GetApiCall';
import useLocationState from '../Medicine/hooks/useLocationState';
import DoctorCard from './DoctorCard';
import { FiSearch } from "react-icons/fi";
import { headerLogo } from '../../images';
import './doctorStyle.css';
import MediLoader from '../Medicine/animation/MediLoader';

const Doctor = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const dispatch = useDispatch();
  const DoctorId = useLocationState();

  // Fetch doctor list from Redux store
  const DoctorListData = useSelector(state => state.doctor_list);
  const DoctorListLoader = useSelector(state => state.doctor_list.loader);
  const DoctorData = DoctorListData?.data?.data;

  // Fetch doctors on component mount
  useEffect(() => {
    dispatch(fetchDoctorList(DoctorId));
  }, [dispatch, DoctorId]);

  // Filter doctors by search term
  const filteredDoctorsBySearch = DoctorData?.filter(doctor => {
    const name = doctor?.doctor_name?.toLowerCase() || ""; // Default to empty string if undefined
    const category = doctor?.category?.toLowerCase() || "";
    const city = doctor?.city?.toLowerCase() || "";

    const searchTermLower = searchTerm.toLowerCase(); // Convert search term to lowercase once
    return (
      name.includes(searchTermLower) ||
      category.includes(searchTermLower) ||
      city.includes(searchTermLower)
    );
  });

  console.log(filteredDoctorsBySearch)

  const height = "75vh"

  return (
    <div className='home-review-banner'>
      {/* Header */}
      <div className="home-app-header sticky-top">
        <div className="header-logo">
          <img src={headerLogo} width="100%" alt="header-logo" />
          <div className="user-name-text-repo">
            <p style={{ fontSize: '16px', marginLeft: '10px' }}>Doctor</p>
          </div>
        </div>
      </div>

      {/* Search bar */}
      <div className="search-bar">
        <input
          type="text"
          placeholder="Search doctor..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onKeyPress={(e) => {
            const regex = /^[a-zA-Z\s]*$/; // Allow only letters and spaces
            if (!regex.test(e.key)) {
              e.preventDefault(); // Prevent invalid input
            }
          }}
        />
        <FiSearch />
      </div>

      {/* Doctor List */}

      {DoctorListLoader ? (
        <MediLoader height={height} />
      ) : (
        <div className="doctor-grid">
          {filteredDoctorsBySearch?.length === 0 && (
            <div className="no-doctor-found" style={{ height: '60vh', display: 'flex', alignItems: 'center' }}>
              <p style={{ fontSize: '18px', fontWeight: '500' }}>No Doctor Found</p>
            </div>
          )}
          {filteredDoctorsBySearch?.map((doctor, index) => (
            <DoctorCard key={index} doctor={doctor} />
          ))}
        </div>
      )}
    </div>
  );
};

export default Doctor;
