import React, { useEffect, useState } from 'react';
import useLocationState from '../Medicine/hooks/useLocationState';
import { headerLogo } from '../../images';
import { hospitalIcon, HospitalServicesIcon } from '../Medicine/Images';
import { FaBuilding } from 'react-icons/fa';
import { IoLocationSharp } from "react-icons/io5";
import { Swiper, SwiperSlide } from 'swiper/react';
import { DoctorData } from '../../data';
import DoctorCard from './DoctorCard';
import { useDispatch, useSelector } from 'react-redux';
import { fetchHospitalDetails } from '../../Redux/actions/GetApiCall';
import { FaTelegramPlane } from "react-icons/fa";
import LoaderAnime from '../Medicine/animation/prescription_loader'
import { IoBed } from "react-icons/io5";

const HospitalDetails = () => {
    const Data = useLocationState();
    console.log(Data)
    const [activeTab, setActiveTab] = useState("Overview");
    const [selectedCategory, setSelectedCategory] = useState('All');
    const dispatch = useDispatch();
    const [modalShow, setModalShow] = useState(false)
    const HospitalDetailData = useSelector(state => state.hospital_details)
    console.log(HospitalDetailData?.data?.data?.id)

    useEffect(() => {
        if (Data) {
            dispatch(fetchHospitalDetails(Data?.id))
        }
    }, [Data])


    const [embedUrl, setEmbedUrl] = useState(null);

    const directionsUrl = HospitalDetailData?.data?.data?.google_map_link;

    const extractLocationData = (url) => {
        const regex = /dir\/([^/]+)\/([^/]+)/;
        const match = url?.match(regex);
        if (match) {
            const [latitude, longitude] = match[1].split(",");
            const destination = decodeURIComponent(match[2]);
            return { latitude, longitude, destination };
        }
        return {};
    };

    const locationData = extractLocationData(directionsUrl);
    const { destination } = locationData;

    const getUserLocation = () => {
        return new Promise((resolve, reject) => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        const { latitude, longitude } = position.coords;
                        resolve({ latitude, longitude });
                    },
                    (error) => {
                        console.error("Error fetching location:", error);
                        reject(error);
                    }
                );
            } else {
                console.error("Geolocation is not supported by this browser.");
                reject(new Error("Geolocation not supported"));
            }
        });
    };

    const generateEmbedUrl = async () => {
        try {
            const userLocation = await getUserLocation();
            const { latitude: userLat, longitude: userLng } = userLocation;

            const url = `https://www.google.com/maps/embed/v1/directions?key=AIzaSyBz8qUh5g91GyggiZf6eNqOC6jKMErllxA&origin=${userLat},${userLng}&destination=${destination}`;
            setEmbedUrl(url);
        } catch (error) {
            console.error("Failed to generate embed URL:", error);
        }
    };

    useEffect(() => {
        if (destination) {
            generateEmbedUrl();
        }
    }, [destination]);



    // Function to switch tabs
    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };
    // Extract unique department names for the category filter
    // Create categories, excluding null or undefined departments



    // Filter doctors by both category and search term
    const filteredDoctors = HospitalDetailData?.data?.data?.doctors?.filter(doctor => {
        // Exclude doctors with null or undefined departments
        if (!doctor.departments || doctor.departments.length === 0) {
            return false;
        }

        // Check if the doctor matches the selected category
        const matchesCategory =
            selectedCategory === 'All' ||
            doctor.departments.some(department => department.department_name === selectedCategory);

        // Check if the doctor matches the search term (uncomment and adjust if needed)
        // const matchesSearch = !searchTerm ||
        //     doctor.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        //     doctor.departments.some(department => department.department_name.toLowerCase().includes(searchTerm.toLowerCase())) ||
        //     doctor.city.toLowerCase().includes(searchTerm.toLowerCase());

        // Return true if the category matches (and search term, if applied)
        return matchesCategory;
    });

    const categories = filteredDoctors?.length > 0
        ? ['All', ...new Set(filteredDoctors.flatMap(doctor =>
            doctor.departments.map(department => department.department_name)
        ))]
        : [...new Set(filteredDoctors?.flatMap(doctor =>
            doctor.departments.map(department => department.department_name)
        ))];



    useEffect(() => {
        if (HospitalDetailData?.status === "loading") {
            setModalShow(true)
        }
        else {
            setModalShow(false)
        }
    }, [HospitalDetailData])


    return (
        <div className='home-review-banner'>
            {modalShow && <LoaderAnime showModal={modalShow} />}
            <div className="home-app-header sticky-top">
                <div className="header-logo">
                    <img src={headerLogo} width="100%" alt="header-logo" />
                    <div className="user-name-text-repo">
                        <p style={{ fontSize: '16px', marginLeft: '10px' }}>Hospital Details</p>
                    </div>
                </div>
            </div>

            {/* Hospital Basic Info */}
            <div className="doctor-data-repo" style={{ paddingTop: '0px' }}>
                <div className="hospital-tepo-image">
                    <img src={hospitalIcon} alt="Hospital Icon" />
                </div>
                <div className="doctor-data-box-tepo">
                    <h5 style={{ marginBottom: '0px' }}>{HospitalDetailData?.data?.data?.name}</h5>
                    {HospitalDetailData?.data?.data?.speciality_type && (
                        <p style={{ fontSize: "12px", color: "#757575", marginBottom: '5px' }}>{HospitalDetailData?.data?.data?.speciality_type}</p>
                    )}
                    {HospitalDetailData?.data?.data?.city && (
                        <p className="doctor-category">
                            <IoLocationSharp /> {HospitalDetailData?.data?.data?.city}
                        </p>
                    )}
                    {HospitalDetailData?.data?.data?.address && (
                        <p className="doctor-category">
                            <FaBuilding /> {HospitalDetailData?.data?.data?.address}
                        </p>
                    )}
                    {HospitalDetailData?.data?.data?.number_of_beds && (
                        <p className="doctor-category">
                            <IoBed /> No.of beds: {HospitalDetailData?.data?.data?.number_of_beds}
                        </p>
                    )}
                </div>
            </div>

            {/* Timing Data */}
            <div className="consultation-timing-wrapper-box">

            </div>

            {/* Tab Navigation */}
            <div className="tab-navigation" style={{ marginTop: '5px' }}>
                <button onClick={() => handleTabClick("Overview")} className={activeTab === "Overview" ? "active-tab" : ""}>Overview</button>
                <button
                    onClick={() => handleTabClick("Doctors")}
                    className={activeTab === "Doctors" ? "active-tab" : ""}
                >
                    Doctors{HospitalDetailData?.data?.data?.doctors?.length > 0 && ` (${HospitalDetailData?.data?.data?.doctors?.length})`}
                </button>

                <button onClick={() => handleTabClick("Services")} className={activeTab === "Services" ? "active-tab" : ""}>Services</button>
            </div>

            {/* Tab Content */}
            <div className="tab-content">
                {activeTab === "Overview" && (
                    <div className='about-repo-control'>
                        {HospitalDetailData?.data?.data?.about_hospital !== null && (
                            <>
                                <h5>About</h5>
                                <p>{HospitalDetailData?.data?.data?.about_hospital}</p>
                            </>
                        )}
                        {HospitalDetailData?.data?.data?.achievement !== null && (
                            <p><strong style={{ color: "black" }}>Important Info:</strong> {HospitalDetailData?.data?.data?.achievement}</p>
                        )}
                        {HospitalDetailData?.data?.data?.operating_since !== null && (
                            <p><strong style={{ color: "black" }}>Serving since: </strong>{HospitalDetailData?.data?.data?.operating_since} </p>
                        )}

                        <div className="clinic-details-card unique-clinic-card">
                            {/* <h3 className="unique-heading">Clinic Details</h3> */}

                            {/* Map Section with iframe */}
                            <div className="iframe-map-container unique-map-container">
                                <iframe
                                    className="unique-map-iframe"
                                    src={embedUrl}
                                    loading="lazy"
                                    title="Google Map"
                                ></iframe>

                                <button
                                    className="direction-icon unique-direction-icon"
                                    onClick={() => window.open(directionsUrl, "_blank")}
                                    title="Click for Directions"
                                >
                                    <FaTelegramPlane />
                                </button>
                            </div>

                            {/* Clinic Info Section */}
                            <div className="clinic-info unique-clinic-info">
                                <h4 className="unique-clinic-name">{HospitalDetailData?.data?.data?.name}</h4>
                                <p className="unique-clinic-address">{HospitalDetailData?.data?.data?.address}</p>
                                {/* <div className="ratings unique-ratings">
                                    <span>⭐</span>
                                    <p className="unique-rating-value">4.5</p>
                                </div> */}
                                <div className="timings unique-timings">
                                    <h5 className="unique-timings-heading">Timings</h5>
                                    <p className="unique-timings-days">{HospitalDetailData?.data?.data?.operational_hours}</p>
                                    {/* <p className="unique-timings-hours">08:00 AM - 10:00 PM</p> */}
                                    {/* <span className="status unique-status">Open Today</span> */}
                                </div>
                                <div className="timings unique-timings">
                                    <h5 className="unique-timings-heading">Email</h5>
                                    <p className="unique-timings-days">{HospitalDetailData?.data?.data?.contact_email}</p>
                                    {/* <p className="unique-timings-hours">08:00 AM - 10:00 PM</p> */}
                                    {/* <span className="status unique-status">Open Today</span> */}
                                </div>
                            </div>

                            {/* Contact Button */}
                            {/* <button
                                className="contact-clinic-button unique-contact-button"
                                onClick={() => {
                                    window.location.href = `tel:${HospitalDetailData?.data?.data?.contact_number}`;
                                }}
                            >
                                Contact Clinic
                            </button> */}

                        </div>
                    </div>
                )}

                {activeTab === "Doctors" && (
                    <div>
                        <div className="filter-buttons">
                            <Swiper
                                spaceBetween={0}
                                slidesPerView={2}
                                effect="slide"
                                grabCursor={true}

                            >
                                <div className="swiper-wrapper">
                                    {categories.map((category, index) => (
                                        <SwiperSlide
                                            key={index}
                                            className={`repo-cate-doc ${selectedCategory === category ? 'btn-active' : ''}`}
                                            onClick={() => setSelectedCategory(category)}
                                        >
                                            {category}
                                        </SwiperSlide>
                                    ))}
                                </div>
                            </Swiper>
                        </div>

                        <div className="doctor-grid">
                            {(filteredDoctors === undefined || filteredDoctors?.length === 0) && (
                                <div className="no-doctor-found" style={{ height: '60vh', display: 'flex', alignItems: 'center' }}>
                                    <p style={{ fontSize: '18px', fontWeight: '500' }}>No Doctor Found</p>
                                </div>
                            )}
                            {filteredDoctors?.map((doctor, index) => (
                                <DoctorCard key={index} doctor={doctor} hospitalId={HospitalDetailData?.data?.data?.id} hospitalAddress={HospitalDetailData?.data?.data?.address}/>
                            ))}
                        </div>

                    </div>
                )}

                {activeTab === "Services" && (
                    <div className='hospital-services-repo-wrapper-clover'>
                        <h5><img src={HospitalServicesIcon} alt="HospitalIcon" /> Services</h5>
                        <ul>
                            {HospitalDetailData?.data?.data?.services?.map((item, index) => (
                                <li>{item?.services_name}</li>
                            ))}
                        </ul>
                        {/* Add more specific services here if available */}
                    </div>
                )}
            </div>
        </div>
    );
};

export default HospitalDetails;
