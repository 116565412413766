import { thunk } from "redux-thunk"; // Correct import for redux-thunk
import {
  callDoctor,
  deleteAddress,
  deletePrescriptionReducer,
  medicineAddressReducer,
  medicineCartData,
  medicineCartDelete,
  medicineCartDetailsReducer,
  medicineListReducer,
  medicineRecentListReducer,
  medicineReducer,
  offerListReducer,
  packageListReducer,
  pincodeReducer,
  prescriptionListReducer,
  diagnosticCartAddReducer,
  diagnosticRemoveCartReducer,
  searchDiagnosticTest,
  saveAddress,
  selectedAddress,
  singleMedicineReducer,
  uploadPrescriptionReducer,
  diagnosticCartDetailsReducer,
  patientmemberlistReducer,
  addPatientMembersReducers,
  deletePatientReducer,
  savePatientMembersReducers,
  getTimeslotReducers,
  getDiagnosticTestListReducers,
  diagnosticOrderDetailsReducers,
  getPackageListReducers,
  orderMedicineReducer,
  orderlistReducer,
  package_registration_reducers,
  package_verify_reducers,
  capture_user_package_reducers,
  generate_otp_reducers,
  verify_otp_reducers,
  captureOrderMedicineReducer,
  saveSlotTimeReducers,
  orderDiagnosticReducers,
  selectedDiganosticAddressReducers,
  captureDiagnosticPaymentDetailsReducers,
  categoriesListReducers,
  orderHistoryReducer,
  orderHistory,
  trackOrderReducer,
  uploadDiagnosticPrescription_reducers,
  fetchDiagnosticPrescription_list_reducers,
  deleteDiagnosticPrescriptionReducer,
  cancelOrderMedicineReducer,
  ReOrderMedicineReducer,
  tele_department_list_reducers,
  department_time_slot_reducers,
  bookTeleconsult_reducers,
  test_descriptions_reducers,
  getpackageDescription_reducers,
  LabOrderReducer,
  saveuploadprescriptionReducer,
  wallet_utilization_details_reducers,
  get_consultation_reducers,
  utilize_amount_reducers,
  captureTeleconsultPaymentDetailsReducers,
  LabOrderDetailsReducer,
  cancelOrderDiagnosticReducer,
  ReOrderDiagnosticReducer,
  health_record_reducers,
  need_help_reducers,
  lab_reports_reducers,
  HomeReducer,
  TeleconsultationListReducer,
  TeleconsultationListDetailsReducer,
  WellnessFormReducer,
  PackageDetailReducer,
  rebookTeleconsultation,
  tele_symptoms_list_reducers,
  boatandyodareducer,
  reimbursementreducer,
  reimbursementformreducer,
  savereimbursementreducer,
  HelpApireducer,
  privacy_policy_reducers,
  termsCondition_reducers,
  tele_banners_list_reducers,
  ThankYouReducer,
  FitnessProreducer,
  Employee_medicine_cart_list_reducer,
  Employee_medicine_order_list_reducer,
  Employee_package_list_reducer,
  Employee_medicine_cart_reducer,
  Employee_medicine_delete_reducer,
  Employee_medicine_order_reducer,
  Employee_auto_thank_reducer,
  RadioTestsReducer,
  HomeRadioReducer,
  HomeRadioCategoriesReducer,
  HomeRadioPackagesReducer,
  HomeRadioListReducer,
  radioCartAddReducer,
  radioRemoveCartReducer,
  radioaddPatientMembersReducers,
  radiodeletePatientReducer,
  radiosavePatientMembersReducers,
  radioCartDetailsReducer,
  getRadioTestListReducers,
  getpackageRadio_reducers,
  radiopatientmemberlistReducer,
  radiosaveSlotTimeReducers,
  saveSlotTimeRadioReducers,
  getTimeslotRadioReducers,
  orderRadiologyReducers,
  radiologyOrderDetailsReducers,
  captureRadiologyPaymentDetailsReducers,
  RadiologylistDetailsReducer,
  RadiologyOrderReducer,
  ReOrderRadiologyReducer,
  cancelOrderRadiologyReducer,
  getRadioPackageListReducers,
  deleteRadiologyPrescriptionReducer,
  uploadRadiologyPrescriptionReducer,
  fetchRadiologyPrescription_list_reducers,
  yoga_session_reducers,
  dental_book_reducers,
  eye_book_reducers,
  opd_book_reducers,
  HospitalCategoriesListReducers,
  HospitalListReducers,
  HospitalServicesReducers,
  HospitalDetailsReducers,
  DoctorDetailsReducers,
  radiostateReducers,
  radiocityReducers,
  DoctorListReducers,
  DoctorPatientFormReducers,
  DoctorOrderDetailReducers,
  DoctorAmountUtilizationReducers,
  DoctorCapturePaymentReducers,
  DoctorOrderListReducers,
  radiocenterReducers,
  rebookOpd,
  DoctorRecentReducers,
  DoctorBannersReducers,
  HospitalRecentReducers,
  DoctorTimeSlotReducers,
} from "../MultipleReducer/multipleReducer";
import { applyMiddleware, combineReducers, createStore } from "redux";

// Combine all reducers into the root reducer
const rootReducer = combineReducers({
  // GET APIs Reducers
  medicine: medicineReducer,
  medicine_list: medicineListReducer,
  medicine_recent_list: medicineRecentListReducer,
  prescription_list: prescriptionListReducer,
  medicine_cart_list: medicineCartDetailsReducer,
  single_medicine_list: singleMedicineReducer,
  offer_list: offerListReducer,
  package_list: packageListReducer,
  medicine_address: medicineAddressReducer,
  medicine_pincode: pincodeReducer,
  medicine_order_list: orderlistReducer,
  order_history: orderHistory,
  home_list: HomeReducer,
  teleconsultation_list: TeleconsultationListReducer,
  teleconsultation_list_details: TeleconsultationListDetailsReducer,
  track_order: trackOrderReducer,
  fitness_pro: FitnessProreducer,
  thankyou_data: ThankYouReducer,
  package_details: PackageDetailReducer,
  lab_order_details: LabOrderReducer,
  radiology_order_details: RadiologyOrderReducer,
  lab_order_Data_Details: LabOrderDetailsReducer,
  reiumbursement_Details: reimbursementreducer,
  reiumbursement_form_Details: reimbursementformreducer,
  save_reiumbursement_form_Details: savereimbursementreducer,

  // POST APIs Reducers
  wellness_form: WellnessFormReducer,
  boat_and_yoga: boatandyodareducer,
  teleconsultation_rebook: rebookTeleconsultation,
  opd_rebook: rebookOpd,
  upload_Prescription: uploadPrescriptionReducer,
  delete_Prescription: deletePrescriptionReducer,
  medicine_cart_add: medicineCartData,
  medicine_cart_delete: medicineCartDelete,
  call_doctor: callDoctor,
  save_address: saveAddress,
  delete_address: deleteAddress,
  selected_address: selectedAddress,
  order_medicine: orderMedicineReducer,
  help_api_details: HelpApireducer,
  capture_order_medicine: captureOrderMedicineReducer,
  cancel_order_medicine: cancelOrderMedicineReducer,
  cancel_order_diagnotic: cancelOrderDiagnosticReducer,
  cancel_order_radiology: cancelOrderRadiologyReducer,
  reorder_medicine: ReOrderMedicineReducer,
  reorder_diagnostic: ReOrderDiagnosticReducer,
  reorder_radiology: ReOrderRadiologyReducer,
  save_upload_prescription: saveuploadprescriptionReducer,

  // DIAGNOSTICS
  search_diagnostic_test: searchDiagnosticTest,
  patient_members_list: patientmemberlistReducer,
  radio_patient_members_list: radiopatientmemberlistReducer,

  // POST APIs Reducers for Diagnostics
  diagnostic_cart_add: diagnosticCartAddReducer,
  diagnostic_remove_cart: diagnosticRemoveCartReducer,
  diagnostic_cart_details: diagnosticCartDetailsReducer,
  radio_cart_details: radioCartDetailsReducer,
  add_patient_members: addPatientMembersReducers,
  delete_patient_members: deletePatientReducer,
  save_patient_members: savePatientMembersReducers,
  get_time_slots: getTimeslotReducers,
  get_time_slots_radio: getTimeslotRadioReducers,
  diagnostic_test_list_response: getDiagnosticTestListReducers,
  radio_test_list_response: getRadioTestListReducers,
  diagnostic_package_list_response: getPackageListReducers,
  radiology_package_list_response: getRadioPackageListReducers,
  diagnostic_place_order_details_response: diagnosticOrderDetailsReducers,
  radiology_place_order_details_response: radiologyOrderDetailsReducers,
  radiology_order_list_response: RadiologylistDetailsReducer,
  getpackageDescription_response: getpackageDescription_reducers,
  getpackageRadio_response: getpackageRadio_reducers,
  upload_diagnostic_prescription_response:
    uploadDiagnosticPrescription_reducers,
  diagnosticPrescription_list_response:
    fetchDiagnosticPrescription_list_reducers,
  radiologyPrescription_list_response: fetchRadiologyPrescription_list_reducers,
  diagnosticPrescription_delete_response: deleteDiagnosticPrescriptionReducer,
  radiologyPrescription_delete_response: deleteRadiologyPrescriptionReducer,
  radiology_diagnostic_prescription_response:
    uploadRadiologyPrescriptionReducer,

  // Package and OTP Reducers
  package_registration_response: package_registration_reducers,
  package_verify_response: package_verify_reducers,
  capture_user_package_response: capture_user_package_reducers,
  generate_otp_response: generate_otp_reducers,
  verify_otp_response: verify_otp_reducers,

  // Additional POST APIs Reducers
  save_time_slot_response: saveSlotTimeReducers,
  radio_save_time_slot_response: saveSlotTimeRadioReducers,
  orderDiagnostic_response: orderDiagnosticReducers,
  orderRadiology_response: orderRadiologyReducers,
  save_diagnostic_address_response: selectedDiganosticAddressReducers,
  capture_diagnostic_payment_details_response:
    captureDiagnosticPaymentDetailsReducers,
  capture_radiology_payment_details_response:
    captureRadiologyPaymentDetailsReducers,
  capture_teleconsult_payment_details_response:
    captureTeleconsultPaymentDetailsReducers,
  categories_test_list_response: categoriesListReducers,
  getTestDescription_response: test_descriptions_reducers,
  tele_department_list_response: tele_department_list_reducers,
  tele_symptoms_list_response: tele_symptoms_list_reducers,
  department_time_slot_response: department_time_slot_reducers,
  bookTeleconsult_response: bookTeleconsult_reducers,
  wallet_details_response: wallet_utilization_details_reducers,
  get_consultation_details_response: get_consultation_reducers,
  utilize_amount_response: utilize_amount_reducers,
  health_record_response: health_record_reducers,
  need_help_response: need_help_reducers,
  lap_report_response: lab_reports_reducers,

  privacy_response: privacy_policy_reducers,
  terms_condition_response: termsCondition_reducers,
  tele_banners_response: tele_banners_list_reducers,

  // ==== auto login cart=====
  // auto_medicine_cart_list: Employee_medicine_cart_list_reducer,
  // auto_medicine_order_list: Employee_medicine_order_list_reducer,
  // auto_package_list: Employee_package_list_reducer,
  // auto_medicine_cart: Employee_medicine_cart_reducer,
  // auto_medicine_delete: Employee_medicine_delete_reducer,
  // auto_medicine_order: Employee_medicine_order_reducer,
  auto_thank_you: Employee_auto_thank_reducer,
  yoga_session_response: yoga_session_reducers,
  dental_book_response: dental_book_reducers,
  eye_book_response: eye_book_reducers,
  opd_book_response: opd_book_reducers,

  // === Radiology ===
  home_list_radio: HomeRadioReducer,
  home_list_radio_categories: HomeRadioCategoriesReducer,
  home_list_radio_packages: HomeRadioPackagesReducer,
  home_list_radio_search: HomeRadioListReducer,

  radio_cart_add: radioCartAddReducer,
  radio_remove_cart: radioRemoveCartReducer,
  // diagnostic_cart_details: radioCartDetailsReducer,
  radio_add_patient_members: radioaddPatientMembersReducers,
  radio_delete_patient_members: radiodeletePatientReducer,
  radio_save_patient_members: radiosavePatientMembersReducers,
  radio_state: radiostateReducers,
  radio_city: radiocityReducers,
  radio_centers: radiocenterReducers,

  //Hospital
  hospital_categories_list: HospitalCategoriesListReducers,
  hospital_list: HospitalListReducers,
  hospital_services: HospitalServicesReducers,
  hospital_details: HospitalDetailsReducers,
  doctor_details: DoctorDetailsReducers,
  doctor_list: DoctorListReducers,
  doctor_patient_form: DoctorPatientFormReducers,
  doctor_order_detail: DoctorOrderDetailReducers,
  doctor_amount_utilization: DoctorAmountUtilizationReducers,
  doctor_capture_amount: DoctorCapturePaymentReducers,
  doctor_order_list: DoctorOrderListReducers,
  doctor_recent_list: DoctorRecentReducers,
  hospital_recent_list: HospitalRecentReducers,
  doctor_banner: DoctorBannersReducers,
  doctor_time_slot: DoctorTimeSlotReducers,
});

// Create the Redux store with the root reducer and thunk middleware
const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;
