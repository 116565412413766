import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router";
import SuccessRadiology from "../Animation/SuccessRadiology";
import { useDispatch, useSelector } from "react-redux";
import { fetchStateRadio } from "../Redux/actions/GetApiCall";
import Select from 'react-select';
import { radioCity } from "../Redux/actions/PostApiCall";

const RadiologyModal = (props) => {

  const [cityList, setCityList] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [successmodal, setSuccessModal] = useState(false);
  const navigate = useNavigate();
  const [modalShown, setModalShown] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const dispatch = useDispatch();
  const stateData = useSelector(state => state.radio_state);
  const cityData = useSelector(state => state.radio_city);

  useEffect(() => {
    if (modalShown) {
      dispatch(fetchStateRadio());
    }
  }, [modalShown, dispatch]);

  const handleModalShow = () => {
    setModalShown(true);
  };

  const handleStateChange = async (selectedOption) => {
    setSelectedState(selectedOption);
    setSelectedCity(null);
    const data = { state_id: selectedOption?.value };
    await dispatch(radioCity(data));
  };

  const handleCityChange = (selectedOption) => {
    setSelectedCity(selectedOption);
  };

  const handleSubmit = () => {
    props.setModalShow(false);
    setSuccessModal(true);
    setModalShow(true);
    setTimeout(() => {
      setSuccessModal(false);
      setModalShow(false);
      navigate("/radio-home");
      window.location.reload();
    }, 3000);

    const selectedData = {
      selectedState: selectedState?.label,
      selectedCity: selectedCity?.label,
      selectedCityId: selectedCity?.value
    };

    // Save selected data in localStorage
    localStorage.setItem("selectedRadiologyData", JSON.stringify(selectedData));

    setModalShown(false);
  };

  // Map state data to options format for react-select
  const stateOptions = stateData?.data?.data?.map((state) => ({
    value: state?.id,
    label: state?.name,
  })) || [];

  // Map city data to options format for react-select
  const cityOptions = cityData?.data?.data.map((city) => ({
    value: city?.id,
    label: city?.name,
  }));

  const handleClose = () => {
    const radiologyCityData = localStorage.getItem('selectedRadiologyData');
    if (!radiologyCityData) {
      navigate('/home')
    }
    else {
      props.setModalShow(false);
    }
  };

  return (
    <>
      <Modal
        {...props}
        centered
        show={props.show}
        onHide={handleClose}
        backdrop="static"
        onShow={handleModalShow}
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className="modal-heading-text">
            Select State and City
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="input-box-content">
            <Select
              className="radio-for-radio"
              value={selectedState}
              onChange={handleStateChange}
              options={stateOptions}
              placeholder="Select State"
              isSearchable
            />
            <br />
            <Select
              className="radio-for-radio"
              value={selectedCity}
              onChange={handleCityChange}
              options={cityOptions}
              placeholder="Select City"
              isSearchable
              isDisabled={!selectedState}
            />
            <br />
            <button onClick={handleSubmit} disabled={!selectedState || !selectedCity}>
              Submit
            </button>
          </div>
        </Modal.Body>
      </Modal>
      {successmodal && <SuccessRadiology show={modalShow} />}
    </>
  );
};

export default RadiologyModal;
