import React, { useCallback, useEffect, useRef, useState } from 'react'
import { headerLogo } from '../../../images'
import { FiSearch } from 'react-icons/fi';
import { hospitalIcon, no_medicine_found } from '../../Medicine/Images';
import { FaAngleRight } from 'react-icons/fa';
import { debounce } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { fetchHospitalList } from '../../../Redux/actions/GetApiCall';
import MediLoader from '../../Medicine/animation/MediLoader';
import { useNavigate } from 'react-router';

const SearchHospital = () => {
    const [inputValue, setInputValue] = useState('');
    const { data: SearchData, error: SearchError, loader: SearchLoader } = useSelector(state => state?.hospital_list);
    const dispatch = useDispatch()
    const [searchResult, setSearchResult] = useState([]);
    const navigate = useNavigate()
    const debounceFetchMedicine = useCallback(
        debounce((value) => {
            if (value.trim() !== '') {
                dispatch(fetchHospitalList("", value));
            } else {
                setSearchResult([]);
            }
        }, 1000),
        [dispatch]
    );

    const inputRef = useRef(null);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    useEffect(() => {
        if (inputValue !== "") {
            debounceFetchMedicine(inputValue);
        }
        return () => {
            debounceFetchMedicine.cancel();
        };
    }, [inputValue, debounceFetchMedicine]);

    useEffect(() => {
        setSearchResult(SearchData);
        if (inputValue === "") {
            setSearchResult([]);
        }
    }, [SearchData, inputValue]);

    const handleInputChange = (e) => {
        const value = e.target.value;
        setInputValue(value);
        if (value.trim() === '') {
            setSearchResult([]);
        }
    };

    const height = "75%";

    const getHighlightedText = (text, highlight) => {
        const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
        return parts.map((part, index) =>
            part.toLowerCase() === highlight.toLowerCase() ? <strong key={index} style={{ color: 'black' }}>{part}</strong> : part
        );
    };

    const handleSearch = (id) => {
        setInputValue('');
        const data = {
            id: id
        };

        navigate('/hospital-details', { state: data });
    };

    return (
        <div className='home-review-banner'>
            <div className="home-app-header sticky-top">
                <div className="header-logo">
                    <img src={headerLogo} width="100%" alt="header-logo" />
                    <div className="user-name-text-repo">
                        <p style={{ fontSize: '16px', marginLeft: '10px' }}>Search Hospital</p>
                    </div>
                </div>
            </div>

            <div className="search-bar">
                <input
                    type="text"
                    placeholder="Search hospitals..."
                    onChange={(e) => handleInputChange(e)}
                    ref={inputRef}
                    onKeyPress={(e) => {
                        const regex = /^[a-zA-Z\s]*$/;  // Regular expression to allow letters and spaces only
                        if (!regex.test(e.key)) {
                            e.preventDefault(); // Prevent non-letter characters
                        }
                    }}
                />
                <FiSearch />
            </div>

            {
                SearchLoader ? (
                    <MediLoader height={height} />
                ) : (
                    <div className="data-repo-cover-medi-search">
                        {searchResult && searchResult?.data?.filter(item => item.search_term !== "undefined").length > 0 ? (
                            searchResult.data
                                .filter(item => item.search_term !== "undefined") // Filter out items with search_term as "undefined"
                                .map((item) => (
                                    <div className="search-result-data-box-cover" key={item?.id} onClick={() => handleSearch(item?.id)}>
                                        <div className="search-data-box-cont">
                                            <img
                                                src={hospitalIcon}

                                                alt="product-icon"
                                            />
                                            <p className='ellipsis-text'>{getHighlightedText(item?.name, inputValue)}</p>
                                        </div>
                                        <div className="search-left-arrow-box">
                                            <FaAngleRight />
                                        </div>
                                    </div>
                                ))
                        ) : (
                            <p className='no-medicine-found'>
                                <img src={no_medicine_found} alt="" />
                            </p>
                        )}
                    </div>
                )
            }

        </div>
    )
}

export default SearchHospital