import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router";
import SuccessRadiology from "../Animation/SuccessRadiology";

const TeleconsultantModal = (props) => {
    // New state for selected city
    const [successmodal, setSuccessModal] = useState(false);
    const navigate = useNavigate();
    const [modalShow, setModalShow] = React.useState(false);
    const [selectedPackage, setSelectedPackage] = useState(localStorage.getItem('PackageSelectData') || null);
    const [rehit, setRehit] = useState(false);
    const V2HREF = localStorage.getItem("V2HREF")
    const NutritionID = localStorage.getItem("NutritionID");
    

    const { handleShow, show } = props

    useEffect(() => {
        if (props.packageList && props.packageList?.length === 0) {
            setModalShow(false);
        } else {
            setModalShow(true);
        }
    }, [props.packageList])

    const handleSelectChange = (event) => {
        const selectedPackage = JSON.parse(event.target.value);
        setSelectedPackage(selectedPackage);
    };



    const handleSubmit = () => {
        if (V2HREF && selectedPackage && selectedPackage.id && selectedPackage?.name) {
            const selectDataPackage = {
                id: selectedPackage?.id,
                name: selectedPackage?.name,
            };
            localStorage.setItem("PackageSelectData", JSON.stringify(selectDataPackage));
        }
        else if (selectedPackage && selectedPackage.id && selectedPackage?.name) {
            const selectDataPackage = {
                id: selectedPackage?.id,
                name: selectedPackage?.name,
            };
            localStorage.setItem("PackageSelectData", JSON.stringify(selectDataPackage));
        } else {
            console.error("Selected package is not defined or missing required properties.");
        }

        setRehit(true);
        props.onHide();
        setSuccessModal(true);
        setModalShow(true);
    };

    const handleClose = () => {
        if (V2HREF && V2HREF !== null) {
            window.location.href = V2HREF;
        }
        else if (selectedPackage) {
            props?.setShow(false);
        }
        else {
            navigate("/home");
        }
    };

    console.log('successmodal')
    console.log(successmodal)
    console.log(V2HREF)
    console.log(NutritionID)


    useEffect(() => {
        if (
            successmodal &&
            V2HREF && V2HREF !== "null" &&
            NutritionID && NutritionID !== "null"
        ) {
            setTimeout(() => {
                setSuccessModal(false);
                setModalShow(false);
                navigate("/book-teleconsultation", { state: selectedPackage?.id });
            }, 3000);
        } else if (successmodal) {
            setTimeout(() => {
                setSuccessModal(false);
                setModalShow(false);
                navigate("/teleconsultation", { state: selectedPackage?.id, rehit });
            }, 3000);
        }

    }, [successmodal, rehit, navigate, NutritionID, V2HREF]);

    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered
                className="tele-modal-data"

            >
                <Modal.Header closeButton>
                    <Modal.Title
                        id="contained-modal-title-vcenter"
                        className="modal-heading-text"
                    >
                        Select Package Name
                    </Modal.Title>
                    {props.packageList?.length > 0 ? null : (
                        <button onClick={handleClose} className="btn-close" aria-label="Close" />
                    )}
                </Modal.Header>
                <Modal.Body>
                    <div className="input-box-content">
                        <Form.Select
                            aria-label="Default select example"
                            className="radio-form"
                            onChange={handleSelectChange}
                            value={selectedPackage ? JSON.stringify(selectedPackage) : ''}
                        >
                            <option value='' disabled>Select Package</option>
                            {props?.packageList?.map((state) => (
                                <option key={state.id} value={JSON.stringify(state)}>
                                    {state?.name}
                                </option>
                            ))}
                        </Form.Select>
                        <br />
                        <button onClick={handleSubmit} disabled={!selectedPackage || selectedPackage === ''}>Submit</button>
                    </div>
                </Modal.Body>
            </Modal>
            {successmodal && <SuccessRadiology show={modalShow} />}
        </>
    );
};

export default TeleconsultantModal;
