import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { IoIosSearch } from "react-icons/io";
import { fetchDiagnosticCartDetails, getDiagnosticTestList, getDiagnosticPackageList, fetchCategoriesTestList, getTestsRadio, fetchRadioApi, fetchRadioCategoriesApi, fetchRadioPackagesApi, fetchRadioCartDetails, getRadioTestList } from "../../Redux/actions/GetApiCall";
import { useDispatch } from "react-redux";
import { useFetchTestCategories } from "../../QueryClient/DiagnosticQuery";
import {
  handleNavigateSearch,
  handleNavigateSearchRadio,
} from "../../utils/AllFunction";
import Loader from "../../Animation/Loader";
import AppFooter from "../Medicine/Components/Medicine_footer"
import NoDataFoundComp from "../../components/NoDataFound";
import { Toaster, toast } from "react-hot-toast";
import { useSelector } from "react-redux";

import {
  MY_ACCOUNT_API
} from "../../Constants";
// import TestbyCategories from "./components/TestbyCategories";
// import HowitWorks from "./components/HowitWorks";
// import TestCard from "./components/TestCard";
import AppHeader from "../Medicine/Components/Medicine_Header"
import {
  appLabUploadImg,
  appMachineTest,
  Radiology_check,
} from "../../images";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import HealthPackages from "../LabTestPages/components/HealthPackages";
import HowitWorks from "../LabTestPages/components/HowitWorks";
import TestCard from "../LabTestPages/components/TestCard";
import TestbyCategorieSection from "../LabTestPages/components/TestbyCategoriesSection";
import RadioPackages from "./RadioPackages";
import RadioTestCard from "./RadioTestCard";
import RadioFooter from "./RadioFooter";
import RadioCategoryList from "./RadioCategoryList";

const RadioHome = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const key = "radiology"
  const testData = useSelector(state => state.radio_test_list_response);
  const cartDetailsResponse = useSelector(state => state.radio_cart_details);
  const diagnostic_package_list_response = useSelector(state => state.home_list_radio_packages);
  const categories_test_list_response = useSelector(state => state.home_list_radio_categories);
  // Retrieve and parse the data from localStorage
  const radiologyCityData = JSON.parse(localStorage.getItem('selectedRadiologyData'));

  // Now you can access `selectedCityId` from the parsed object
  console.log(radiologyCityData?.selectedCityId);



  // const location = useLocation();
  // const queryParams = new URLSearchParams(location.search);
  // const V2token = queryParams.get("token");
  // const token = localStorage.getItem("ACCESS_TOKEN");

  useEffect(() => {
    dispatch(fetchRadioCartDetails())
    dispatch(getRadioTestList(radiologyCityData?.selectedCityId))
    dispatch(fetchRadioPackagesApi(radiologyCityData?.selectedCityId))
    dispatch(fetchRadioCategoriesApi(radiologyCityData?.selectedCityId))
  }, [dispatch])


  return (
    <>
      <div className="home-review-banner">
        <AppHeader Title="Radiology" labCartCount={cartDetailsResponse?.data?.response?.cart_count} prescrip="diagnostics" radio="radio" />
        <div className="app-searh-section lab-search-bar mt-0 mb-4">
          <div
            className="inputWithIcon"
            onClick={() => handleNavigateSearchRadio(navigate)}
          >
            <input type="text" placeholder="Search" />
            <button>
              <IoIosSearch />
            </button>
          </div>
        </div>
        <div className="lab-upload">
          <div className="container upload-repo">
            <div className="lab-prescription-upload-box" style={{ paddingLeft: '12px' }}>
              <div className="row">
                <div className="col-lg-7 col-md-7 col-7 lab-content-repo">
                  <div className="lab-prescription-content">
                    <h6>Upload Prescription</h6>
                    <p>Placed order with prescription</p>
                  </div>
                  <div className="lab-prescription-image">
                    <NavLink to={`/radio-prescription`}>
                      <button>
                        <img
                          src={appLabUploadImg}
                          width="100%"
                          alt="lab-upload"
                        />
                        <p>Upload Prescription</p>
                      </button>
                    </NavLink>
                  </div>
                </div>
                <div className="col-lg-5 col-md-5 col-5 d-flex align-items-center justify-content-center">
                  <div className="test-image">
                    <img src={Radiology_check} width="100%" alt="test-machine" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="lab-book-categories mt-4">
          <div className="book-test">
            <h6>Book Test by categories</h6>
            <NavLink to="/radio-categories-test">
              <p>View All</p>
            </NavLink>
          </div>
          {categories_test_list_response?.loader ? (
            <Loader />
          ) : (
            <RadioCategoryList categoryListData={categories_test_list_response?.data?.data} />
          )}
        </div>
        <div className="common-test-wrapper">
          <div className="book-test">
            <h6>Commonly Book Test</h6>
            <NavLink to="/radio-common-book-test">
              <p>View All</p>
            </NavLink>
          </div>
          <div className="container">
            <Swiper
              spaceBetween={10}
              slidesPerView={1.7}
              loop="true"
              modules={[Autoplay]}
              pagination={{ clickable: true }}
            >
              <div className="swiper-wrapper">
                {testData?.data?.data?.length > 0 ? (
                  testData?.data?.data?.map((test, index) => (
                    <SwiperSlide key={index}>
                      <div className="col-lg-12 common-repo mb-4">
                        <RadioTestCard test={test}
                          loader={testData.loader}
                          callCategories={false}
                        />
                      </div>
                    </SwiperSlide>
                  ))
                ) : (
                  <NoDataFoundComp />
                )}
              </div>
            </Swiper>
          </div>
        </div>
        <RadioPackages packageData={diagnostic_package_list_response?.data} />
        <HowitWorks />
        <Toaster
          position="top-center"
          reverseOrder={false}
          gutter={8}
          containerClassName=""
          containerStyle={{}}
          toastOptions={{
            // Define default options
            className: "",
            duration: 2000,
            style: {
              background: "#fff",
              color: "#363636",
            },
          }}
        />
        {(cartDetailsResponse?.data?.response?.tests?.length > 0 || cartDetailsResponse?.data?.response?.packages?.length > 0) && (
          <RadioFooter labCartData={cartDetailsResponse?.data?.response} tabkey={key} />
        )}
      </div>
    </>
  );
};

export default RadioHome;
